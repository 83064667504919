//@flow

import { BreakpointContext } from '../context/breakpointContext'
import { useContext } from 'react'

function getResponsiveValue(
  val: string | number | (string | number)[]
): string | number {
  const breakpoint = useContext(BreakpointContext)
  if (Array.isArray(val)) {
    return breakpoint < val.length ? val[breakpoint] : val[val.length - 1]
  }
  return val
}

export default getResponsiveValue
