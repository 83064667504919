//@flow
import * as React from 'react'
import Testimonial from '../../../molecules/testimonial'
import { Link } from 'prismic-reactjs'
import { linkResolver } from '../../../../utils/linkResolver'

import { type PageCtaProps } from '../pageCta'
import {
  type PrismicLink,
  type RichTextAry,
} from '../../../../types/flow/gatsby-source-prismic-graphql'

export type PageCtaData = {|
  type: 'cta',
  primary: {
    description: RichTextAry,
    section_title: RichTextAry,
    button_link: PrismicLink,
    button_text: string,
    testimonial?: {
      name: string,
      location: string,
      testimonial: RichTextAry,
    },
  },
|}

function getTestimonial(t) {
  if (t) {
    const { location, name, testimonial } = t
    return (
      <Testimonial
        data={{
          name,
          testimonial: testimonial[0].text,
          locality: location,
        }}
      ></Testimonial>
    )
  }
  return null
}

export function modelPageCtaData({ primary }: PageCtaData): PageCtaProps {
  const {
    section_title,
    description,
    button_link,
    button_text,
    testimonial,
  } = primary
  return {
    heading: section_title[0].text,
    description: description[0].text,
    buttonLink: Link.url(button_link, linkResolver),
    buttonText: button_text,
    testimonial: getTestimonial(testimonial),
  }
}
