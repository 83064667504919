//@flow

import * as React from 'react'

import Text from './text'

import type { StyledSystemTypographyProp } from '../../../types/flow/styled-system'

export function Title1({
  as = `h1`,
  fontFamily = [1],
  fontSize = [5, 6, 6, 7, 8],
  letterSpacing = [4],
  lineHeight = [1],
  color = 'primary',
  mb = 3,
  mt = 2,
  children,
  ...props
}: StyledSystemTypographyProp) {
  const typographyProps = {
    as,
    fontFamily,
    fontSize,
    letterSpacing,
    lineHeight,
    color,
    mb,
    mt,
    ...props,
  }
  return <Text {...typographyProps}>{children}</Text>
}

export function Title2({
  as = 'h2',
  fontFamily = [1],
  fontSize = [4, 5, 5, 6, 6],
  letterSpacing = [3],
  lineHeight = [2],
  color = 'primary',
  mb = 3,
  mt = 2,
  children,
  ...props
}: StyledSystemTypographyProp) {
  const typographyProps = {
    as,
    fontFamily,
    fontSize,
    letterSpacing,
    lineHeight,
    color,
    mb,
    mt,
    ...props,
  }
  return <Text {...typographyProps}>{children}</Text>
}

export function Title3({
  as = 'h3',
  fontFamily = 1,
  //mb = 2,
  fontSize = [3, 4, 4, 5, 5],
  letterSpacing = 3,
  lineHeight = 1,
  color = 'primary',
  mb = 3,
  mt = 3,
  children,
  ...props
}: StyledSystemTypographyProp) {
  const typographyProps = {
    as,
    fontFamily,
    fontSize,
    letterSpacing,
    lineHeight,
    color,
    mb,
    mt,
    ...props,
  }
  return <Text {...typographyProps}>{children}</Text>
}

export function Title4({
  as = 'h4',
  fontFamily = 1,
  fontSize = [2, 3, 3, 4, 4],
  letterSpacing = 5,
  lineHeight = 2,
  color = 'primary',
  mb = 1,
  mt = 2,
  children,
  ...props
}: StyledSystemTypographyProp) {
  const typographyProps = {
    as,
    fontFamily,
    fontSize,
    letterSpacing,
    lineHeight,
    color,
    mb,
    mt,
    ...props,
  }
  return <Text {...typographyProps}>{children}</Text>
}
