//@flow

import * as React from 'react'
import { Box } from '../../atoms/cards'
import { Text } from '../../atoms/text/text-new'
import HorizontalList from '../../atoms/lists/horizontalList'
import { RichText } from '../../molecules/richTextArticle'

import { type RichTextAry } from '../../../types/prismicSchema'

type PropTypes = {
  heading: RichTextAry,
  locations: RichTextAry[],
}

function LocationList({ heading, locations, ...props }: PropTypes) {
  return (
    <Box {...props}>
      <RichText render={heading} />
      <HorizontalList items={locations} />
      <Text mt={4} typography="paragraph">
        And surrounding areas
      </Text>
    </Box>
  )
}

export default LocationList
