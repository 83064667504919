//@flow
import { css } from 'styled-components'
import { css as systemCss } from '@styled-system/css'
import { variant, compose } from 'styled-system'
import { textStyles } from '../../text/textStyles'
import { baseStyles, styling, typography, positioning } from './baseStyles'

export const defaultStyles = variant({
  variants: {
    primary: {
      bg: 'primaryShades.20',
      color: 'primaryShades.60',
      borderColor: 'primaryShades.50',
      boxShadow: 'p1',
    },
    secondary: {
      bg: 'secondaryShades.30',
      color: 'primaryShades.60',
      borderColor: 'primaryShades.60',
      boxShadow: 's1',
    },
    inverted: {
      bg: 'base.0',
      color: 'primaryShades.60',
      borderColor: 'primaryShades.50',
      boxShadow: 'p1',
    },
    submit: {
      bg: 'modes.fern.primaryShades.40',
      color: 'modes.fern.primaryShades.60',
      borderColor: 'modes.fern.primaryShades.60',
      //boxShadow: 'p1',
      opacity: 0.6,
    },
    'submit-success': {
      bg: 'modes.fern.primaryShades.30',
      color: 'modes.fern.primaryShades.60',
      borderColor: 'modes.fern.primaryShades.60',
      boxShadow: 'p1',
      opacity: 1,
    },
  },
})

export const hoverStyles = variant({
  variants: {
    primary: {
      bg: 'primaryShades.10',
      color: 'primaryShades.80',
      borderColor: 'primaryShades.60',
      boxShadow: 'p1',
    },
    secondary: {
      bg: 'secondaryShades.30',
      color: 'primaryShades.80',
      borderColor: 'primaryShades.60',
      boxShadow: 's1',
    },
    inverted: {
      bg: 'base.10',
      color: 'primaryShades.80',
      borderColor: 'primaryShades.60',
      boxShadow: 'p1',
    },
    submit: {
      bg: 'modes.fern.primaryShades.30',
      color: 'modes.fern.primaryShades.60',
      borderColor: 'modes.fern.primaryShades.50',
      cursor: 'not-allowed',
      transition: 'all 0.1s',
    },
    'submit-success': {
      bg: 'modes.fern.primaryShades.40',
      color: 'defaultText.20',
      borderColor: 'modes.fern.primaryShades.60',
      boxShadow: 's1',
      opacity: 1,
      transition: 'all 0.1s',
    },
  },
})

export const focusStyles = variant({
  variants: {
    primary: {
      borderColor: 'secondaryShades.50',
      bg: 'primaryShades.30',
      boxShadow: 'p2',
    },
    secondary: {
      borderColor: 'secondaryShades.50',
      bg: 'secondaryShades.30',
      boxShadow: 'p2',
    },
    inverted: {
      bg: 'base.10',
      borderColor: 'secondaryShades.50',
      color: 'primaryShades.80',
      boxShadow: 'p2',
    },
    submit: {
      bg: 'modes.fern.primaryShades.40',
      color: 'invertedText.0',
      boxShadow: 'p2',
      borderColor: 'modes.fern.primaryShades.90',
    },
  },
})

export const activeStyles = variant({
  variants: {
    primary: {
      borderColor: 'secondaryShades.50',
      bg: 'primaryShades.30',
      boxShadow: 'p2',
    },
    secondary: {
      borderColor: 'secondaryShades.50',
      bg: 'secondaryShades.30',
      boxShadow: 'p2',
    },
    inverted: {
      borderColor: 'primaryShades.50',
      bg: 'base.10',
      boxShadow: 'none',
    },
    submit: {
      borderColor: 'modes.fern.primaryShades.60',
      color: 'invertedText.30',
      bg: 'modes.fern.primaryShades.50',
      boxShadow: 'none',
    },
    'submit-success': {
      borderColor: 'modes.fern.primaryShades.60',
      color: 'defaulText.40',
      bg: 'modes.fern.primaryShades.40',
      boxShadow: 'none',
    },
  },
})

export const disabledStyles = variant({
  variants: {
    primary: {
      bg: 'primaryShades.20',
      borderColor: 'primaryShades.30',
      color: 'primaryShades.30',
      boxShadow: 'none',
    },
    secondary: {
      bg: 'primaryShades.20',
      borderColor: 'primaryShades.30',
      color: 'primaryShades.30',
      boxShadow: 'none',
    },
    inverted: {
      bg: 'primaryShades.20',
      borderColor: 'primaryShades.30',
      color: 'primaryShades.30',
      boxShadow: 'none',
    },
    submit: {
      opacity: 0.4,
      boxShadow: 'none',
    },
  },
})

const hoverFocusRing = systemCss({
  borderColor: 'secondaryShades.50',
  content: '',
  top: -1,
  right: -1,
  bottom: -1,
  left: -1,
  zIndex: -1,
  borderRadius: 4,
})

export default css`
  ${baseStyles}
  ${compose(
    styling,
    typography,
    textStyles,
    defaultStyles,
    positioning
  )}
  &:visited {
    ${defaultStyles}
  }
  &:hover {
    ${hoverStyles}
  }
  &:focus {
    outline: none;
    ${focusStyles}
    &::before {
      position: absolute;
      content: '';
      border: solid 2px;
      ${hoverFocusRing}
    }
  }
  &:active {
    ${activeStyles}
  }
  &:disabled {
    cursor: not-allowed;
    ${disabledStyles}
  }
`
