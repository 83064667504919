//@flow
import * as React from 'react'
import Background from '../../containers/background'
import LayoutBox from '../../containers/layoutBox'
import { Box } from '../../atoms/cards'
import FlexColumnGrid from '../../containers/flexColumnGrid'

type ContainerProps = {
  background?: React.Node,
  foreground?: React.Node,
}

function Container({ background, foreground, ...props }: ContainerProps) {
  return (
    <Background
      py={[0, 0, 0, 6]}
      mb={[0]}
      flexDirection={['column', 'column', 'column', 'row']}
      alignItems="center"
      position="relative"
      {...props}
    >
      <Box
        position={['relative', 'relative', 'relative', 'absolute']}
        height={[11, 11, 11, '100%']}
        width="100%"
      >
        {background}
      </Box>

      <LayoutBox
        style={{
          userSelect: 'none',
          pointerEvents: 'none',
        }}
        flex={null}
        width="100%"
        bottom={[6, 6, 6, 0]}
        position="relative"
        mx={[0, 0, 0, 5]}
        px={[3, 3, 3, 0]}
      >
        <Box position="relative" mx="auto">
          <FlexColumnGrid columns={8} spaceBetween={3}>
            <Box colSpan={[8, 8, 8, 4, 4, 3]}>{foreground}</Box>
          </FlexColumnGrid>
        </Box>
      </LayoutBox>
    </Background>
  )
}

export default Container
