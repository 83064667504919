//@flow

import * as React from 'react'
import { Flex } from '../atoms/cards'

type BackgroundProps = {
  children?: React.Node,
  [string]: mixed,
}

function Background({ children, ...props }: BackgroundProps) {
  return (
    <Flex
      position="relative"
      alignItems="center"
      justifyContent="center"
      {...props}
    >
      {children}
    </Flex>
  )
}

export default Background
