//@flow

import * as React from 'react'
import { Box } from '../../atoms/cards'
import FlexGrid from '../../containers/flexColumnGrid'
import Background from '../../containers/background'
import LayoutBox from '../../containers/layoutBox'
import HighlightCard from '../../molecules/highlightCard/highlightCard'

import { RichTextAry } from '../../../types/prismicSchema'

export type HighlightsProps = {|
  items: {
    heading: RichTextAry,
    description: RichTextAry,
  }[],
|}

// eslint-disable-next-line react/prop-types
function HighlightGrid({
  data,
  pt = [0, 0, 0, 4],
  pb = [6, 6, 6, 8],
  ...props
}: {
  data: HighlightsProps,
  pt?: mixed,
  pb?: mixed,
}) {
  return (
    <Background pt={pt} pb={pb} {...props} justifyContent="center">
      <LayoutBox>
        <Box
          as="section"
          width={['100%', '100%', '100%', '100%', '100%', '75%']}
          mx="auto"
        >
          <FlexGrid columns={[8, 8, 8, 6, 6]} spaceBetween={[1, 1, 1, 3]}>
            {data.items.map((highlight, i) => (
              <HighlightCard
                colSpan={[8, 8, 6, 2, 2]}
                nudge={[0, 0, 1, 0]}
                key={i}
                height="100%"
                minHeight="100%"
                title={highlight.heading}
                description={highlight.description}
                bg="white"
              />
            ))}
          </FlexGrid>
        </Box>
      </LayoutBox>
    </Background>
  )
}

export default HighlightGrid
