//@flow
import * as React from 'react'
import styled from 'styled-components'
import { variant } from 'styled-system'
import { Box } from './cards'
import { motion } from 'framer-motion'

const barStyles = variant({
  variants: {
    success: {
      backgroundImage: 'linear-gradient(18deg, #93E5C2 0%, #51BBBD 100%)',
    },
    error: {
      backgroundImage: 'linear-gradient(18deg, #E593AC 0%, #BD5151 100%)',
    },
    default: {
      backgroundImage: 'linear-gradient(18deg, #93E5C2 0%, #51BBBD 100%)',
    },
  },
})

const animationVariants = {
  start: { x: '-100%' },
  end: { x: '0%' },
}

const StyledProgressBar = styled(Box)`
  width: 100%;
  ${barStyles}
`

type ProgressBarProps = {
  variant?: 'success' | 'error' | 'default',
  animate: { ...mixed },
  transition?: {
    ...mixed,
  },
  ...mixed,
}

export function ProgressBar({
  variant = 'success',
  animate,
  transition = { ease: 'linear', duration: 4 },
  ...props
}: ProgressBarProps) {
  return (
    <Box position="relative" overflow="hidden" {...props}>
      <StyledProgressBar
        as={motion.div}
        animate={animate}
        variant={variant}
        variants={animationVariants}
        transition={transition}
        initial="start"
        height="8px"
      />
    </Box>
  )
}
