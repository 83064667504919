//@flow

import * as React from 'react'
import styled from 'styled-components'

import { Flex, Box } from '../../atoms/cards'
import FlexColumnGrid from '../../containers/flexColumnGrid'
import Background from '../../containers/background'
import LayoutBox from '../../containers/layoutBox'
import { Button } from '../../atoms/buttons/button-new/button'

import { Text } from '../../atoms/text/text-new'

export type PageCtaProps = {
  testimonial?: React.Node,
  heading: string,
  description: string,
  buttonLink: string,
  buttonText: string,
  [string]: mixed,
}

const FloatingTesti = styled(Box)`
  position: absolute;
  top: 0;
  transform: translateY(-50%);
`

function pageCta({ data, ...props }: { data: PageCtaProps }) {
  const { testimonial, heading, description, buttonLink, buttonText } = data
  return (
    <Background
      position="relative"
      {...props}
      bg="primaryShades.20"
      mt={testimonial ? [11, 11, 11, 12] : 0}
      pt={testimonial ? [11] : [6, 7, 7]}
      pb={7}
    >
      {testimonial && (
        <FloatingTesti>
          <LayoutBox>
            <Box as="aside" width="100%" mx="auto">
              <FlexColumnGrid columns={[8, 8, 8, 12]} spaceBetween={3}>
                <Box
                  colSpan={[8, 8, 6, 8]}
                  nudge={[0, 0, 1, 2]}
                  position="relative"
                >
                  {testimonial}
                </Box>
              </FlexColumnGrid>
            </Box>
          </LayoutBox>
        </FloatingTesti>
      )}

      <LayoutBox>
        <Flex
          as="section"
          width="100%"
          mx="auto"
          flexDirection="column"
          alignItems="center"
        >
          <Text typography="heading2">{heading}</Text>
          <FlexColumnGrid columns={[8, 8, 12, 12, 12, 8]} spaceBetween={3}>
            <Text
              as="p"
              typography="paragraph"
              colSpan={[8, 6, 8, 8, 8, 4]}
              nudge={[0, 1, 2, 2, 2, 2]}
              textAlign="center"
            >
              {description}
            </Text>
          </FlexColumnGrid>
          <Button
            href={buttonLink}
            icon="PHONE"
            mt={5}
            typography="ui"
            variant="inverted"
          >
            {buttonText}
          </Button>
        </Flex>
      </LayoutBox>
    </Background>
  )
}

export default pageCta
