//@flow

import * as React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import { type PrismicImg } from '../../types/flow/prismic'
import { withPreview } from 'gatsby-source-prismic-graphql'

export type PageMeta = {|
  meta_title: string,
  meta_description?: string,
  opengraph_image?: PrismicImg,
  opengraph_title?: string,
  opengraph_description?: string,
  opengraph_locale?: string,
  opengraph_site_name?: string,
  twitter_card_type?: string,
  twitter_site?: string,
  twitter_creator?: string,
|}
type SeoDataProps = {|
  useDefaults?: boolean,
  useTemplate?: boolean,
  path: string,
  article?: boolean,
|}

const schema = {
  '@context': 'http://www.schema.org',
  '@type': 'GeneralContractor',
  name: 'B&W Damp & Timber Preservation',
  url: 'https://www.bwpropertycare.co.uk',
  logo:
    'https://images.prismic.io/bwpropertycare/74460ec7-d034-4d67-8431-844ec798d78d_opengraph-main.jpg',
  priceRange: '$$',
  image:
    'https://images.prismic.io/bwpropertycare%2F6d91fe91-a568-41dc-bd68-001510554621_jesse-roberts-561igityvsk-unsplash.jpg',
  description:
    'Damp-proofing, woodworm treatment, timber preservation, asbestos removal and property surveys.',
  address: {
    '@type': 'PostalAddress',
    streetAddress: 'Dormer House, Carr Lane, Poringland',
    addressLocality: 'Norwich',
    addressRegion: 'Norfolk',
    postalCode: 'NR14 7LT',
    addressCountry: 'United Kingdom',
  },
  geo: {
    '@type': 'GeoCoordinates',
    latitude: '52.565518',
    longitude: '1.338554',
  },
  hasMap:
    'https://www.google.com/maps/place/B%26W+Damp+%26+Timber+Preservation/@52.5658232,1.3372152,17z/data=!3m1!4b1!4m5!3m4!1s0x47d9e0389e223489:0xe7724254d9a9478f!8m2!3d52.5658215!4d1.338391',
  openingHours:
    'Mo 08:30-17:00 Tu 08:30-17:00 We 08:30-17:00 Th 08:30-17:00 Fr 08:30-17:00',
  telephone: '01603860086',
}

const globalSeoQuery = graphql`
  query MyQuery {
    prismic {
      allGlobal_seos {
        edges {
          node {
            seo_data {
              meta_description
              meta_title
              opengraph_description
              opengraph_image
              opengraph_locale
              opengraph_site_name
              opengraph_title
              schema
              twitter_card_type
              twitter_creator
              twitter_site
            }
            canonical_url
          }
        }
      }
    }
  }
`

export function SeoData({
  data,
  useTemplate = true,
  path,
  article = false,
  children,
}: {
  data: PageMeta,
  ...SeoDataProps,
  children?: React.Node,
}) {
  return (
    <StaticQuery
      query={globalSeoQuery}
      render={withPreview(({ prismic }) => {
        const defaults = prismic['allGlobal_seos'].edges
          .slice(0, 1)
          .pop()
          .node.seo_data.slice(0, 1)
          .pop()
        const meta = Object.assign({}, { ...defaults }, { ...data })
        const {
          meta_title,
          meta_description,
          opengraph_image,
          opengraph_title,
          opengraph_description,
          twitter_card_type,
          twitter_site,
          twitter_creator,
        } = meta
        return (
          <Helmet
            titleTemplate={
              useTemplate ? '%s | B&W Damp & Timber Preservation' : undefined
            }
            title={meta_title}
          >
            {meta_description && (
              <meta name="description" content={meta_description} />
            )}
            {path && (
              <meta
                property="og:url"
                content={`https://www.bwpropertycare.co.uk/${path}`}
              />
            )}
            {opengraph_title && (
              <meta property="og:title" content={opengraph_title} />
            )}
            {opengraph_description && (
              <meta property="og:description" content={opengraph_description} />
            )}
            {(article ? true : null) && (
              <meta property="og:type" content="article" />
            )}
            {opengraph_image && [
              <meta
                name="og:image"
                key="og:image"
                content={opengraph_image.url}
              />,
              <meta
                name="twitter:image"
                key="twitter:image"
                content={opengraph_image.url}
              />,
            ]}
            <script type="application/ld+json">{JSON.stringify(schema)}</script>
            {twitter_creator && (
              <meta name="twitter:creator" content={twitter_creator} />
            )}
            {twitter_site && (
              <meta name="twitter:site" content={twitter_site} />
            )}
            {twitter_card_type && (
              <meta name="twitter:card_type" content={twitter_card_type} />
            )}
            <meta name="og:site_name" content="B&W Property Care" />
            <meta name="og:locale" content="en_GB" />
            {children}
          </Helmet>
        )
      }, globalSeoQuery)}
    />
  )
}
