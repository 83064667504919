//@flow

import styled from 'styled-components'
import { Box } from '../../../atoms/cards'

const Frame = styled(Box)`
  width: 100%;
  overflow: hidden;
`

export default Frame
