//@flow

import { type MapSectionProps } from '../mapSection'
import { type RichTextAry } from '../../../../types/prismicSchema'

export type MapSectionData = {|
  type: 'locations',
  primary: {
    leading_sentence: RichTextAry,
  },
  fields: {
    locations: {
      location: string,
      coordinates: {
        latitude: number,
        longitude: number,
      },
    },
  }[],
|}

export function modelLocationSlice({
  primary,
  fields,
}: MapSectionData): MapSectionProps {
  return {
    listHeading: primary.leading_sentence,
    locations: fields.map(i => i.locations),
  }
}
