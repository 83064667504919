//@flow

import * as React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic-graphql'

//import { Formik, Form } from 'formik'

import styled from 'styled-components'
import { useAnimation, motion } from 'framer-motion'
import { system } from 'styled-system'

import { RichText } from '../../molecules/richTextArticle'
import { Flex, Box } from '../../atoms/cards'
import { Button } from '../../atoms/buttons/button-new/button'
import { InnerPageContainer } from '../../containers'

import { type RichTextAry } from '../../../types/flow/prismic'

export type GdprProps = {
  key?: number,
  type: 'gdpr',
  body: {
    heading: RichTextAry,
    message: RichTextAry,
  },
  unmount: () => void,
}

const animationStates = {
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: 400 },
}

const gdprQuery = graphql`
  query {
    prismic {
      allGdpr_notices {
        edges {
          node {
            heading
            message
          }
        }
      }
    }
  }
`

export function GdprPopup({ unmount, ...props }: GdprProps) {
  const mountingControls = useAnimation()

  const enter = async () => {
    await mountingControls.start('visible')
  }
  const close = async () => {
    await mountingControls.start('hidden')
    unmount()
  }

  React.useEffect(() => {
    enter()
  }, [])

  const Background = styled(Box)`
    pointer-events: initial;
    width: 100%;
    outline: 4px solid transparent;
    box-shadow: 0px 0px 16px -2px rgba(0, 0, 0, 0.5);
    ${system({
      outlineColor: {
        property: 'outlineColor',
        scale: 'colors',
      },
    })};
  `

  return (
    <StaticQuery
      data={gdprQuery}
      render={withPreview(({ prismic: { allGdpr_notices } }) => {
        const { heading, message } = allGdpr_notices.edges[0].node

        return (
          <Background
            as={motion.div}
            positionTransition
            variants={animationStates}
            animate={mountingControls}
            transition={{ duration: 0.5 }}
            initial="hidden"
            exit="hidden"
            bg="base.0"
            outlineColor="primaryShades.50"
            {...props}
          >
            <InnerPageContainer py={1} fullWidth>
              <Flex
                justifyContent="space-between"
                alignItems={['flex-start', 'flex-start', 'flex-start']}
                flexDirection={['column', 'column', 'row']}
              >
                <div>
                  <RichText render={heading} />
                  <RichText render={message} />
                </div>
                <Flex
                  flexDirection="column"
                  alignItems={['flex-start', 'flex-start', 'flex-end']}
                  pl={4}
                  pt={2}
                  flex="1 0 auto"
                >
                  <Button
                    buttonStyle="solid"
                    typography="paragraph"
                    variant="submit-success"
                    icon="TICK"
                  >
                    Accept and close
                  </Button>
                  <Button
                    buttonStyle="ghost"
                    typography="paragraph"
                    variant="primary"
                    iconVariant="inverted"
                    icon="CLOSE"
                    mt={2}
                    onClick={() => close()}
                  >
                    Decline
                  </Button>
                </Flex>
              </Flex>
            </InnerPageContainer>
          </Background>
        )
      }, gdprQuery)}
    />
  )
}
