//@flow

import * as React from 'react'
import { useAnimation, motion } from 'framer-motion'
import { variant } from 'styled-system'

import { Flex, Box } from '../../atoms/cards'
import { Button } from '../../atoms/buttons/button-new/button'
import { Text } from '../../atoms/text/text-new'

import { ProgressBar } from '../../atoms/progressBar'

export type ToastProps = {
  key?: number,
  breakpoint: number,
  type: 'toast',
  body: {
    heading: React.Node,
    message?: React.Node,
    variant?: 'success' | 'error' | 'default',
  },
  unmount: () => void,
}

const animationVariants = {
  visible: { opacity: 1, x: 0, y: 0 },
  hidden: { opacity: 0, x: 600 },
  'hidden-mobile': { opacity: 0, y: 600 },
}

const backgroundStyleVariants = variant({
  variants: {
    default: {
      bg: 'base.0',
      borderColor: 'primaryShades.50',
    },
    success: {
      bg: 'modes.fern.primaryShades.10',
      borderColor: 'primaryShades.50',
    },
    error: {
      bg: 'modes.brick.primaryShades.10',
      borderColor: 'modes.brick.primaryShades.50',
    },
  },
})
const headingStyleVariants = variant({
  variants: {
    default: {
      color: 'primaryShades.60',
    },
    success: {
      color: 'primaryShades.60',
    },
    error: {
      color: 'modes.brick.primaryShades.60',
    },
  },
})

export function Toast({ body, breakpoint, unmount, ...props }: ToastProps) {
  const { heading, message, variant } = body

  const mountingControls = useAnimation()
  const progressControls = useAnimation()

  const sequence = async () => {
    await mountingControls.start('visible')
    await progressControls.start('end')
    await mountingControls.start('hidden')
    unmount()
  }
  const close = async () => {
    await mountingControls.stop()
    await progressControls.stop()
    await mountingControls.start('hidden')
    unmount()
  }
  React.useEffect(() => {
    sequence()
  }, [])
  return (
    <Box
      as={motion.div}
      positionTransition
      variant={variant}
      variants={animationVariants}
      initial={breakpoint >= 2 ? 'hidden' : 'hidden-mobile'}
      transition={{ duration: 0.5 }}
      animate={mountingControls}
      exit="hidden"
      px={5}
      py={4}
      maxWidth="100%"
      borderRadius={3}
      border="2px solid"
      position="relative"
      overflow="hidden"
      boxShadow="s1"
      mt={3}
      css={backgroundStyleVariants}
      {...props}
    >
      <Flex alignItems="flex-start" position="relative">
        <Text
          flex="1 1 auto"
          typography="heading4"
          mt={0}
          css={headingStyleVariants}
          variant={variant}
        >
          {heading}
        </Text>
        <Button
          flex="0 0 auto"
          minHeight={2}
          minWidth={2}
          buttonStyle="ghost"
          variant="inverted"
          ml={[1, 2, 3]}
          p={0}
          icon="CLOSE"
          focusRing={-1}
          ringRadius="50%"
          onClick={() => close()}
        />
      </Flex>
      <Box pr={[0, 0, 6]}>
        <Text typography="paragraph">{message}</Text>
      </Box>
      <ProgressBar
        animate={progressControls}
        variant={variant}
        position="absolute"
        bottom="0"
        left="0"
        right="0"
      />
    </Box>
  )
}
