//@flow

import { useFontSize } from '../shared/hooks'

function emToPx(emVal: string): number {
  const bodyFontSize = useFontSize()
  return parseInt(parseFloat(emVal) * bodyFontSize)
}

export default emToPx
