//@flow
import * as React from 'react'
import styled from 'styled-components'
import { positioning } from '../../helpers'
import { ICONS } from '../icons'
import { system } from 'styled-system'

const Svg = styled.svg`
  vertical-align: sub;
  display: inline-block;
  transform-origin: center;
  ${system({
    fill: {
      property: 'fill',
      scale: 'colors',
    },
  })}
`

const I = styled.i`
  align-items: center;
  font-style: normal;
  position: relative;
  ${positioning};
`

type IconProps = {
  icon: string,
  hover?: boolean,
  fill?: string,
  size?: mixed,
  title?: string,
  description?: string,
  viewBox?: string,
  props?: { [string]: mixed },
  svgProps?: { [string]: mixed },
  pathProps?: { [string]: mixed },
  variant?: string,
  hover?: mixed,
}

export function Icon({
  icon,
  svgProps = {},
  pathProps = {},
  ...props
}: IconProps) {
  const symbol = Object.prototype.hasOwnProperty.call(ICONS, icon)
    ? ICONS[icon]
    : icon
  const newSvgProps = Object.assign(
    {},
    { ...svgProps },
    { viewBox: svgProps.viewBox || '0 0 24 24' }
  )
  const newPathProps = Object.assign(
    {},
    { ...pathProps },
    { d: pathProps.d || symbol }
  )
  return (
    <I {...props}>
      <Svg {...newSvgProps}>
        <path {...newPathProps} />
      </Svg>
    </I>
  )
}
