//@flow

import styled, { css } from 'styled-components'

import PrismicImage from '../../../atoms/image'

const hoverState = css`
  transform: scale(1.1);
  opacity: 0.6;
`

const Image = styled(PrismicImage)`
  transition: transform 0.3s ease-in-out, opacity 0.3s;
  will-change: transform opacity;
  ${props => (props.hover || props.focus) && hoverState}
`

export default Image
