//@flow
import { useEffect, useState } from 'react'
import { breakpoints } from '../../theme/breakpoints'
import useFontSize from './useFontSize'

function getBreakPoint(windowWidth: string, fontSize: number) {
  const widthInEms = parseInt(windowWidth) / fontSize
  return [...breakpoints, 999999].findIndex(b => widthInEms < parseInt(b))
}

export function useBreakpoint(): number {
  const fontSize = useFontSize()
  const [breakPoint, setBreakpoint] = useState(0)
  useEffect(() => {
    const newBreakpoint = getBreakPoint(window.innerWidth, fontSize)
    setBreakpoint(newBreakpoint)
    const handleResize = () => {
      return setBreakpoint(newBreakpoint)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [breakPoint])
  return breakPoint
}
