//@flow

import * as React from 'react'
import Container from './container'
import Map from '../../atoms/googleMap'
import LocationBox from './locationBox'

export type MapSectionProps = {
  listHeading: string,
  locations: {
    location: string,
    coordinates: { latitude: number, longitude: number },
  }[],
}

function MapSection({ data, ...props }: { data: MapSectionProps }) {
  const { listHeading, locations } = data
  const offsets = { x: [0, 0, 0, -11, -12, -12], y: 0 }
  const locationNames = locations.map(l => l.location)
  const markerPositions = locations.reduce((acc, l) => {
    const { latitude, longitude } = l.coordinates
    return acc.concat({
      lat: latitude,
      lng: longitude,
    })
  }, [])
  const els = {
    foreground: (
      <LocationBox
        listHeading={listHeading}
        locations={locationNames}
      ></LocationBox>
    ),
    background: (
      <Map
        offsetMap={offsets}
        height="100%"
        markerPositions={markerPositions}
        {...props}
      />
    ),
  }
  return <Container as="section" {...props} {...els} />
}

export default MapSection
