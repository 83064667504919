//@flow

import * as React from 'react'
import styled from 'styled-components'
import { Text } from '../../text/text-new'

import { type RichTextAry } from '../../../../types/prismicSchema'

export type HorizontalListProps = {
  items: (RichTextAry | React.Node)[],
  [string]: mixed,
}

const Container = styled.ul`
  box-sizing: border-box;
  list-style: disc inside none;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
`

function HorizontalList({ items, ...props }: HorizontalListProps) {
  return (
    <Container {...props}>
      {items.map((l, i) => (
        <Text
          display="inline-item"
          as="li"
          width="50%"
          key={i}
          typography="listItem"
        >
          {l}
        </Text>
      ))}
    </Container>
  )
}

export default HorizontalList
