//@flow

exports.colors = {
  base: {
    '0': '#FFFFFF',
    '10': '#FAFAFA',
    '20': '#EBEBEB',
    '30': '#E0E0E0',
    '40': '#9A9A9A',
    '50': '#2E2E2E',
    '60': '#0F0F0F',
    '100': '#000000',
  },
  defaultText: {
    '0': '#000000',
    '10': 'rgba(5, 5, 5, 0.95)',
    '20': 'rgba(10, 10, 10, 0.90)',
    '30': 'rgba(15, 15, 15, 0.85)',
    '40': 'rgba(20, 20, 20, 0.80)',
    '50': 'rgba(26, 26, 26, 0.75)',
    '60': 'rgba(31, 31, 31, 0.70)',
    '70': 'rgba(36, 36, 36, 0.65)',
    '80': 'rgba(41, 41, 41, 0.60)',
    '90': 'rgba(46, 46, 46, 0.55)',
  },
  invertedText: {
    '0': '#FFFFFF',
    '10': 'rgba(250, 250, 250, 0.95)',
    '20': 'rgba(245, 245, 245, 0.90)',
    '30': 'rgba(240, 240, 240, 0.85)',
    '40': 'rgba(235, 235, 235, 0.80)',
    '50': 'rgba(230, 230, 230, 0.75)',
    '60': 'rgba(224, 224, 224, 0.70)',
    '70': 'rgba(219, 219, 219, 0.65)',
    '80': 'rgba(214, 214, 214, 0.60)',
    '90': 'rgba(209, 209, 209, 0.55)',
  },
  white: '#ffffff',
  grey5: '#F3F7FA',
  grey30: '#C4C9CB',
  grey70: '#3F3F3F',
  grey80: '#2A2A2A',
  grey90: '#0D0D0D',
  black: '#000000',
  modes: {
    main: {
      primary: '#11204B',
      primaryA11y: '#FEFEFE',
      primaryA11y10: '#DEDFE1',
      primary10: '#DDE8EE',
      primary90: '#000826',
      secondary: '#5AC7BA',
      secondaryA11y: '#000826',
      primaryShades: {
        '0': '#ffffff',
        '10': '#F2FAFF',
        '20': '#DFEEF7',
        '30': '#A8C6DC',
        '40': '#5A82AA',
        '50': '#183769',
        '60': '#091C48',
        '80': '#020727',
        '100': '#000000',
      },
      secondaryShades: {
        '0': '#ffffff',
        '30': '#3DEBDF',
        '40': '#25D9D0',
        '50': '#00B9B7',
        '100': '#000000',
      },
    },
    ash: {
      primaryShades: {
        '0': '#ffffff',
        '10': '#F4F4F4',
        '20': '#E9E9E9',
        '30': '#C9C9C9',
        '40': '#949393',
        '50': '#373737',
        '60': '#1C1C1C',
        '80': '#0F0F0F',
        '100': '#000000',
      },
      secondaryShades: {
        '0': '#ffffff',
        '30': '#FFAC44',
        '40': '#FF8F17',
        '50': '#EF610F',
        '100': '#000000',
      },
      primary: '#373737',
      primaryA11y: '#FEFEFE',
      primary10: '#F4F4F4',
      primary90: '#0D0D0D',
      secondary: '#FFAC44',
      secondaryA11y: '#0f0f0f',
    },
    teal: {
      primary: '#264375',
      primaryA11y: '#FFFFFF',
      primaryA11y10: '#DCF4F1',
      primary10: '#F3FDFB',
      primary90: '#264375',
      secondary: '#52B4CF',
      secondaryA11y: '#F3FDFB',
      primaryShades: {
        '0': '#ffffff',
        '10': '#E1F7F2',
        '20': '#C5EBE7',
        '30': '#94CDCD',
        '40': '#6EA9B5',
        '50': '#4B768B',
        '60': '#2C3C59',
        '80': '#14182A',
        '100': '#000000',
      },
      secondaryShades: {
        '0': '#ffffff',
        '30': '#52B4CF',
        '40': '#3494B7',
        '50': '#207098',
        '100': '#000000',
      },
    },
    fern: {
      primary: '#3A9663',
      primaryA11y: '#FEFEFE',
      primaryA11y10: '#',
      primary10: '#',
      primary90: '#161F33',
      secondary: '#',
      secondaryA11y: '#161F33',
      primaryShades: {
        '0': '#ffffff',
        '10': '#EEFCED',
        '20': '#D9F9D9',
        '30': '#BCEDC1',
        '40': '#8BD397',
        '50': '#3A9663',
        '60': '#1A4F43',
        '80': '#091C1A',
        '100': '#000000',
      },
      secondaryShades: {
        '0': '#ffffff',
        '30': '#26B874',
        '40': '#159463',
        '50': '#076F5B',
        '100': '#000000',
      },
    },
    flame: {
      primary: '#',
      primaryA11y: '#0F0F0F',
      primaryA11y10: '#',
      primary10: '#',
      primary90: '#0F0F0F',
      secondary: '#',
      secondaryA11y: '#',
      primaryShades: {
        '0': '#ffffff',
        '10': '#FBF6E0',
        '20': '#FBF3C1',
        '30': '#F2E18C',
        '40': '#E6C955',
        '50': '#CDA42A',
        '60': '#795B2C',
        '80': '#1D1713',
        '100': '#000000',
      },
      secondaryShades: {
        '0': '#ffffff',
        '30': '#F2BE47',
        '40': '#DBA028',
        '50': '#BC7A0F',
        '100': '#000000',
      },
    },
    fawn: {
      primary: '#',
      primaryA11y: '#FEFEFE',
      primaryA11y10: '#',
      primary10: '#',
      primary90: '#161F33',
      secondary: '#',
      secondaryA11y: '#',
      primaryShades: {
        '0': '#ffffff',
        '10': '#F1F5E7',
        '20': '#E0E6D5',
        '30': '#C2CBBA',
        '40': '#A7B49D',
        '50': '#646F5C',
        '60': '#354435',
        '80': '#161E19',
        '100': '#000000',
      },
      secondaryShades: {
        '0': '#ffffff',
        '30': '#58D3AA',
        '40': '#3DBA98',
        '50': '#259B89',
        '100': '#000000',
      },
    },
    blush: {
      primary: '#',
      primaryA11y: '#FEFEFE',
      primaryA11y10: '#',
      primary10: '#',
      primary90: '#161F33',
      secondary: '#',
      secondaryA11y: '#',
      primaryShades: {
        '0': '#ffffff',
        '10': '#F8E7F2',
        '20': '#F3D0EA',
        '30': '#D99ECD',
        '40': '#AD74AD',
        '50': '#714D84',
        '60': '#3C325A',
        '80': '#191827',
        '100': '#000000',
      },
      secondaryShades: {
        '0': '#ffffff',
        '30': '#B86ACE',
        '40': '#964BB4',
        '50': '#623394',
        '100': '#000000',
      },
    },
    brick: {
      primary: '#',
      primaryA11y: '#FEFEFE',
      primaryA11y10: '#',
      primary10: '#',
      primary90: '#161F33',
      secondary: '#',
      secondaryA11y: '#',
      primaryShades: {
        '0': '#ffffff',
        '10': '#FCF5F3',
        '20': '#F8DDD9',
        '30': '#EDA2A2',
        '40': '#C8656F',
        '50': '#882A3C',
        '60': '#55152C',
        '80': '#2B081C',
        '100': '#000000',
      },
      secondaryShades: {
        '0': '#ffffff',
        '30': '#C15050',
        '40': '#A52C34',
        '50': '#891926',
        '100': '#000000',
      },
    },
  },
}
