//@flow

import * as React from 'react'
import Background from './background'
import LayoutBox from './layoutBox'
import { Box } from '../atoms/cards'

export type ArticleData = {
  pt?: mixed,
  pb?: mixed,
  children?: React.Node,
  fullWidth?: boolean,
}
function InnerPageContainer({
  pt = [8],
  pb = [6],
  children,
  fullWidth,
  ...props
}: ArticleData) {
  const padding = { pb, pt }
  return (
    <Background {...padding} {...props}>
      <LayoutBox>
        {fullWidth ? (
          children
        ) : (
          <Box width={['100%', '100%', '100%', '100%', '75%']} mx="auto">
            {children}
          </Box>
        )}
      </LayoutBox>
    </Background>
  )
}

export default InnerPageContainer
