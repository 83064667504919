//@flow
import * as React from 'react'
import { Link } from 'gatsby'
import solidStyles from './solidStyles'
import { Icon } from './iconStyles'
import ghostStyles from './ghostStyles'

type ButtonStyles = 'solid' | 'ghost'

const getButtonStyles = (style: ButtonStyles) => {
  switch (style) {
    case 'ghost':
      return ghostStyles
    default:
      return solidStyles
  }
}

type ButtonProps = {
  buttonStyle?: ButtonStyles,
  type?: string,
  to?: string,
  href?: string,
  px?: mixed,
  onClick?: (*) => mixed,
  children?: React.Node,
  icon?: 'PHONE' | 'CLOSE' | 'MENU' | 'PLANE' | 'TICK',
  viewBox?: string,
  spaceBetween?: mixed,
  iconRight?: boolean,
  disabled?: boolean,
  variant?: string,
  iconVariant?: string,
  children?: React.Node,
  borderRadius?: mixed,
  minHeight?: mixed,
  textDecoration?: string,
  render?: (*) => React.Node,
}

export function Button({
  buttonStyle = 'solid',
  px = 4,
  borderRadius = 3,
  minHeight = 4,
  onClick,
  icon,
  spaceBetween = 2,
  children,
  disabled,
  render,
  ...props
}: ButtonProps) {
  const handleClick = e => {
    e.preventDefault()
    return onClick && onClick(e)
  }

  const buttonStyles = getButtonStyles(buttonStyle)

  const [hover, setHover] = React.useState(false)
  const [focus, setFocus] = React.useState(false)
  const [active, setActive] = React.useState(false)
  const [toggled, setToggled] = React.useState(false)
  const toggle = () => setToggled(!toggled)

  const symbol = icon && (
    <Icon
      hover={hover}
      focus={focus}
      active={active}
      disabled={disabled}
      icon={icon}
      viewBox={props.viewBox}
      variant={props.iconVariant || props.variant}
      ml={children && props.iconRight && spaceBetween}
      mr={children && !props.iconRight && spaceBetween}
    />
  )

  const inner = render ? (
    render({ hover, focus, active, disabled })
  ) : symbol ? (
    <>
      {React.Children.toArray([
        symbol && !props.iconRight ? symbol : null,
        children,
        symbol && props.iconRight ? symbol : null,
      ])}
    </>
  ) : (
    children
  )

  let as
  if (props.to) {
    as = Link
  } else if (props.href) {
    as = 'a'
  }

  const newProps = Object.assign(
    {},
    { render, px, disabled, borderRadius, minHeight },
    { ...props }
  )

  return (
    <button
      onMouseEnter={() => setHover(true)}
      onPointerOver={() => setHover(true)}
      onPointerOut={() => setHover(false)}
      onMouseLeave={() => setHover(false)}
      onPointerDown={() => setActive(true)}
      onPointerUp={() => setActive(false)}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      onToggle={() => toggle()}
      css={buttonStyles}
      type={props.type || (!as && 'button') || null}
      as={as}
      href={props.href || null}
      to={props.to || null}
      onClick={onClick ? handleClick : undefined}
      onMouseDown={e => e.preventDefault()}
      {...newProps}
    >
      {inner}
    </button>
  )
}
