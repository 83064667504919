//@flow

import * as React from 'react'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic-graphql'

import Background from '../../containers/background'
import FlexColumnGrid from '../../containers/flexColumnGrid'
import LayoutBox from '../../containers/layoutBox'
import Logo from '../../atoms/logo'
import { Flex, Box } from '../../atoms/cards'
import { Text } from '../../atoms/text/text-new'
import { Button } from '../../atoms/buttons/button-new/button'

type PropTypes = {}

const footerData = graphql`
  query FooterLinksQuery {
    prismic {
      allLegals {
        edges {
          node {
            _meta {
              uid
            }
            main_heading
          }
        }
      }
      allServicess {
        edges {
          node {
            _meta {
              uid
            }
            service_name
          }
        }
      }
    }
  }
`

const Li = styled.li`
  list-style: none;
`

function Footer(props: PropTypes) {
  const [currentYear, setCurrentYear] = React.useState(
    new Date().getFullYear() || 2020
  )
  React.useEffect(() => {
    if (document.body) setCurrentYear(new Date().getFullYear())
  }, [])
  return (
    <StaticQuery
      query={footerData}
      render={withPreview(({ prismic: { allLegals, allServicess } }) => {
        const serviceLinks = allServicess.edges.reduce(
          (
            acc,
            {
              node: {
                _meta: { uid },
                service_name,
              },
            }
          ) => acc.concat([{ uid: uid, name: service_name[0].text }]),
          []
        )
        const legalPageLinks = allLegals.edges.reduce(
          (
            acc,
            {
              node: {
                _meta: { uid },
                main_heading,
              },
            }
          ) => acc.concat([{ uid: uid, name: main_heading[0].text }]),
          []
        )
        return (
          <Background bg="primaryShades.60" {...props}>
            <LayoutBox>
              <Box as="footer" pb={4}>
                <FlexColumnGrid columns={8} spaceBetween={3} mt={6}>
                  <Box colSpan={[8, 7, 4, 3, 2, 2]} mt={[0, 0, -1, 0]}>
                    <Logo height={4} fill="grey5"></Logo>
                    <Flex mt={5} flexDirection="column" alignItems="flex-start">
                      <Button
                        typography="ui"
                        buttonStyle="ghost"
                        variant="inverted"
                        iconVariant="light"
                        focusRing
                        focusOffsetX={-2}
                        href="tel:+441603860086"
                        icon="PHONE"
                        px={0}
                      >
                        01603 860086
                      </Button>
                      <Button
                        typography="ui"
                        buttonStyle="ghost"
                        variant="inverted"
                        iconVariant="light"
                        focusRing
                        focusOffsetX={-2}
                        href="mailto:info@bwpropertycare.co.uk"
                        icon="PLANE"
                        px={0}
                      >
                        info@bwpropertycare.co.uk
                      </Button>
                    </Flex>
                  </Box>
                  <Box colSpan={[8, 7, 4, 3, 4]} mt={[4, 4, 3, 3]}>
                    <Text typography="heading4" as="h4" color="invertedText.30">
                      Services
                    </Text>
                    <FlexColumnGrid
                      as="ul"
                      elementsAs={Li}
                      columns={[1, 1, 1, 1, 2]}
                      spaceBetween={0}
                      mt={3}
                    >
                      {serviceLinks.map((s, i) => (
                        <Button
                          key={i}
                          typography="ui"
                          buttonStyle="ghost"
                          to={`/${s.uid}`}
                          variant="inverted"
                          px={0}
                          py={0}
                          focusOffsetX={-2}
                          focusRing
                        >
                          {s.name}
                        </Button>
                      ))}
                    </FlexColumnGrid>
                  </Box>
                  <Box
                    colSpan={[8, 7, 4, 2, 2]}
                    mt={[4, 4, -12, 3]}
                    pt={[0, 0, 5, 0]}
                  >
                    <Text typography="heading4" as="h4" color="invertedText.30">
                      Company
                    </Text>
                    <FlexColumnGrid
                      as="ul"
                      elementsAs={Li}
                      columns={[1, 1, 1, 1, 1]}
                      spaceBetween={0}
                      mt={3}
                    >
                      {legalPageLinks.map((l, i) => (
                        <Button
                          key={i}
                          typography="ui"
                          buttonStyle="ghost"
                          to={`/${l.uid}`}
                          variant="inverted"
                          px={0}
                          py={0}
                          focusOffsetX={-2}
                          focusRing
                        >
                          {l.name}
                        </Button>
                      ))}
                    </FlexColumnGrid>
                  </Box>
                </FlexColumnGrid>
                <Box mt={5}>
                  <Text
                    typography="paragraph"
                    fontSize={0}
                    color="invertedText.90"
                    letterSpacing={3}
                    mt={0}
                  >
                    &copy; B&W Damp & Timber Ltd 2014&ndash;{currentYear}
                  </Text>
                </Box>
              </Box>
            </LayoutBox>
          </Background>
        )
      }, footerData)}
    />
  )
}

export default Footer
