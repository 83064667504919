//@flow

import { useContext } from 'react'
import { PageThemeContext } from '../context'

function usePageTheme(theme: string) {
  const { setTheme } = useContext(PageThemeContext)
  return setTheme(theme)
}

export default usePageTheme
