//@flow

import * as React from 'react'

import { AnimatePresence } from 'framer-motion'
import styled from 'styled-components'
import { variant } from 'styled-system'

import { Flex, Box } from '../../atoms/cards'

const containerStyles = variant({
  variants: {
    wrapper: {
      zIndex: 3,
      position: 'fixed',
      right: 0,
      bottom: 0,
      top: 0,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      flexDirection: 'column',
    },
    toaster: {
      display: 'flex',
      flexDirection: 'column-reverse',
      height: '100%',
      width: ['100%', '100%', 12],
    },
    gdpr: {
      width: '100vw',
    },
  },
})

const Container = styled(Box)`
  pointer-events: none;
  ${containerStyles};
  & > * {
    pointer-events: initial;
  }
`

type FrameProps = {
  toasts?: React.Node,
  gdpr?: React.Node,
}

export function Frame({ toasts, gdpr }: FrameProps) {
  return (
    <Container variant="wrapper">
      {toasts && (
        <Container variant="toaster">
          <AnimatePresence>{toasts}</AnimatePresence>
        </Container>
      )}
      {gdpr && (
        <Container variant="gdpr">
          <AnimatePresence>{gdpr}</AnimatePresence>
        </Container>
      )}
    </Container>
  )
}
