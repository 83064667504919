//@flow

const cssUnit = new RegExp(/(px|em|ex|%|in|cn|mm|pt|pc+)/gim)

function transformValue<T>(val: T | string, transform: (*) => T | string) {
  if (typeof val === 'string' || val instanceof String) {
    const unit = val.match(cssUnit)
    const mod = unit && unit.pop()
    const n = parseFloat(val)
    const newVal = transform(n)
    //$FlowFixMe
    return `${newVal}${mod || ''}`
  }
  return transform(val)
}

function makeResponsiveStyleArray<T, U>(
  styles: T | T[],
  callback: (*) => U
): U[] {
  if (Array.isArray(styles)) {
    //$FlowFixMe
    return styles.map(c => transformValue(c, callback))
  } else {
    //$FlowFixMe
    return transformValue(styles, callback)
  }
}

export default makeResponsiveStyleArray
