//@flow
import * as React from 'react'
import styled from 'styled-components'
import { Flex, Box } from '../atoms/cards'
import { motion, AnimatePresence } from 'framer-motion'
import { Button } from '../atoms/buttons/button-new/button'
import { StaticQuery, graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic-graphql'

type MobileMenuProps = {
  isActive: boolean,
  close: () => void,
}

const menuAnimationStates = {
  hidden: {
    opacity: 0,
    y: -64,
    rotateX: '-30deg',
    scale: 0.9,
  },
  visible: {
    opacity: 1,
    y: 0,
    rotateX: '0deg',
    scale: 1,
  },
}

const serviceData = graphql`
  query LegalsLinksQuery {
    prismic {
      allServicess {
        edges {
          node {
            _meta {
              uid
            }
            service_name
          }
        }
      }
    }
  }
`

const Background = styled(Flex)`
  perspective: 800px;
  position: fixed;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

export function MobileMenu({ close, isActive = true }: MobileMenuProps) {
  return (
    <StaticQuery
      query={serviceData}
      render={withPreview(({ prismic }) => {
        const serviceLinks = prismic.allServicess.edges.reduce(
          (
            acc,
            {
              node: {
                _meta: { uid },
                service_name,
              },
            }
          ) => acc.concat([{ uid: uid, name: service_name[0].text }]),
          []
        )

        return (
          <AnimatePresence>
            {isActive && (
              <Background
                as={motion.div}
                initial="hidden"
                exit="hidden"
                animate={'visible'}
                variants={menuAnimationStates}
                position="fixed"
                top={3}
                right={3}
                left={3}
                zIndex={4}
                px={6}
                border="2px solid"
                borderRadius={4}
                borderColor="base.20"
                boxShadow="p2"
                bg="base.0"
                onClick={close}
              >
                <Button
                  position="absolute"
                  icon="CLOSE"
                  onClick={close}
                  right={4}
                  top={4}
                />
                <Box mt={7}>
                  {serviceLinks.map(({ uid, name }, i) => (
                    <Button
                      to={`/${uid}`}
                      key={i}
                      buttonStyle="ghost"
                      variant="primary"
                      typography="ui"
                      mb={1}
                      width={11}
                      maxWidth="100%"
                    >
                      {name}
                    </Button>
                  ))}
                </Box>
                <Box mt={5}>
                  <Button
                    to="/contact"
                    buttonStyle="solid"
                    variant="secondary"
                    typography="ui"
                    mb={3}
                    width={11}
                    maxWidth="100%"
                    icon="PLANE"
                  >
                    Contact Us
                  </Button>
                </Box>
              </Background>
            )}
          </AnimatePresence>
        )
      }, serviceData)}
    />
  )
}
