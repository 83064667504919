//@flow

import type { HeroProps } from '../hero'
import {
  type RichTextAry,
  type SharpImg,
  type PrismicImg,
} from '../../../../types/flow/gatsby-source-prismic-graphql'

export type HomepageHeroData = {
  main_heading: RichTextAry,
  summary: RichTextAry,
  hero_image: PrismicImg,
  hero_imageSharp: SharpImg,
}

export function modelHomepageHero({
  main_heading,
  summary,
  hero_image,
  hero_imageSharp,
}: HomepageHeroData): HeroProps {
  return {
    imageProps: { ...hero_image, ...hero_imageSharp },
    heading: main_heading,
    subheading: summary,
  }
}

export type ServiceHeroData = {|
  service_name: RichTextAry,
  short_description: RichTextAry,
  main_image: PrismicImg,
  main_imageSharp: SharpImg,
|}

export function modelServiceHero({
  main_image,
  main_imageSharp,
  service_name,
  short_description,
}: ServiceHeroData): HeroProps {
  return {
    imageProps: { ...main_imageSharp, ...main_image },
    heading: service_name,
    subheading: short_description,
  }
}

export type LegalPageHeroData = {|
  hero_image: PrismicImg,
  hero_imageSharp: SharpImg,
  main_heading: RichTextAry,
  summary: RichTextAry,
|}

export function modelLegalPageHero({
  hero_image,
  hero_imageSharp,
  main_heading,
  summary,
}: LegalPageHeroData): HeroProps {
  return {
    imageProps: { ...hero_image, ...hero_imageSharp },
    heading: main_heading,
    subheading: summary,
  }
}

export type FourOhFourHeroData = {|
  backgrounds: {
    theme: {
      four_oh_fourImage: PrismicImg,
      four_oh_fourImageSharp: SharpImg,
    },
  }[],
  heading: RichTextAry,
  subheading: RichTextAry,
|}

export function modelFourOhFourHero({
  backgrounds,
  heading,
  subheading,
}: FourOhFourHeroData): HeroProps {
  const images = backgrounds.slice(0, 1).pop()
  if (images.theme) {
    const { four_oh_fourImage, four_oh_fourImageSharp } = images.theme
    return {
      imageProps: {
        ...four_oh_fourImage,
        ...four_oh_fourImageSharp,
      },
      heading: heading,
      subheading: subheading,
    }
  }
  return {
    heading: heading,
    subheading: subheading,
  }
}

export type ContactHeroData = {|
  title: RichTextAry,
  subtitle: RichTextAry,
  main_image: PrismicImg,
  main_imageSharp: SharpImg,
|}

export function modelContactHero({
  title,
  subtitle,
  main_image,
  main_imageSharp,
}: ContactHeroData): HeroProps {
  return {
    heading: title,
    subheading: subtitle,
    imageProps: { ...main_image, ...main_imageSharp },
  }
}
