//@flow

import styled from 'styled-components'
//$FlowFixMe
import Img from 'gatsby-image/withIEPolyfill'

import { positioning, styling } from '../../helpers/index'

const Image = styled(Img)`
  ${positioning}
  ${styling}
`
export default Image
