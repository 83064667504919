//breakpoints - must be whole numbers
const breakpoints = ['22em', '36em', '50em', '66em', '82em']
//aliases
breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]
breakpoints.xl = breakpoints[3]
breakpoints.xxl = breakpoints[4]

exports.breakpoints = breakpoints
