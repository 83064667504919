//@flow

import { useState, useEffect } from 'react'

function useFontSize(): number {
  const [fontSize: number, setFontSize: number => number] = useState(16)
  useEffect(() => {
    const bodyFontSize = parseInt(
      window
        ? window.getComputedStyle(document.body).getPropertyValue('font-size')
        : 16
    )
    setFontSize(bodyFontSize)
  }, [])
  return fontSize
}

export default useFontSize
