//@flow

import styled, { css } from 'styled-components'
import { Box } from '../../../atoms/cards'
const { theme } = require('../../../../theme')

const { space, radii } = theme

const PADDING = space[2]
const BORDER_RADIUS = radii[2]

const hoverBackground = (isHovered: boolean) => css`
  display: block;
  box-sizing: border-box;
  content: '';
  position: absolute;
  top: -${PADDING};
  right: -${PADDING};
  bottom: -${PADDING};
  left: -${PADDING};
  background-color: white;
  opacity: ${isHovered ? '1' : '0'};
  transition: opacity 0.4s;
  will-change: opacity;
  border-radius: ${BORDER_RADIUS};
  z-index: -1;
`

const Container = styled(Box)`
  min-height: 100%;
  width: 100%;
  display: block;
  text-decoration: none;
  position: relative;
  padding: 0;
  &:before {
    ${({ isHovered }: { isHovered: boolean }) => hoverBackground(isHovered)}
  }
`

export default Container
