//@flow

import * as React from 'react'
import Background from '../../containers/background'
import ImageBackground from './styles/background'
import { Text } from '../../atoms/text/text-new'
import { Title1 } from '../../atoms/text/headings'
import LayoutBox from '../../containers/layoutBox'
import { Flex, Box } from '../../atoms/cards'
import CredsBadge from '../../molecules/credsBadge'

import {
  type SharpImg,
  type PrismicImg,
  type RichTextAry,
} from '../../../types/flow/gatsby-source-prismic-graphql'

export type HeroProps = {|
  imageProps?: { ...SharpImg, ...PrismicImg },
  heading: RichTextAry,
  subheading: RichTextAry,
  ctaButton?: {
    label: string,
  },
|}

function Hero({
  data,
  pt = 10,
  pb = 9,
  hideBadge,
  ...props
}: {
  data: HeroProps,
  pt?: mixed,
  pb?: mixed,
  hideBadge?: boolean,
}) {
  if (data) {
    return (
      <Background
        position="relative"
        pt={pt}
        pb={pb}
        justifyContent="center"
        {...props}
      >
        <ImageBackground
          {...data.imageProps}
          position="absolute"
          top="0"
          right="0"
          bottom="0"
          left="0"
        />
        <LayoutBox position="relative">
          <Flex
            flexDirection="column"
            alignItems="center"
            position="relative"
            maxWidth={13}
            mx="auto"
          >
            {data.heading.map((d, i) => {
              //TODO - Make this sort of thing better
              let as = 'h1'
              if (data.subheading.find(d => d.type === 'heading1')) {
                as = 'p'
              }
              return (
                <Title1
                  color="primaryA11y"
                  as={as}
                  key={i}
                  textAlign="center"
                  my={[0]}
                >
                  {d.text}
                </Title1>
              )
            })}
            {data.subheading && (
              <Box mt={[2]}>
                {data.subheading.map((d, i) => {
                  //TODO - Make this sort of thing better
                  let as = 'p'
                  if (d.type === 'heading1') {
                    as = 'h1'
                  }
                  return (
                    <Text
                      mt={[2]}
                      key={i}
                      as={as}
                      typography="paragraph"
                      color="primaryA11y"
                      textAlign="center"
                      lineHeight={[3]}
                      maxWidth={[12]}
                    >
                      {d.text}
                    </Text>
                  )
                }) || 'description'}
              </Box>
            )}
          </Flex>
        </LayoutBox>
        {!hideBadge && (
          <Background
            bg="transparent"
            position="absolute"
            left="0px"
            right="0px"
            bottom="0px"
            justifyContent="center"
          >
            <LayoutBox style={{ transform: 'translateY(50%)' }}>
              <CredsBadge ml="auto" mr={['auto', 'auto', '0px']} />
            </LayoutBox>
          </Background>
        )}
      </Background>
    )
  }
  return null
}

export default Hero
