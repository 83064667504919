//@flow

import styled from 'styled-components'
import { Flex } from '../../../atoms/cards'

export const Li = styled(Flex).attrs({ as: 'li' })`
  position: relative;
  padding: 0;
  list-style: none;
`
export const Ul = styled(Flex).attrs({ as: 'ul' })`
  justify-content: space-between;
  position: relative;
  width: 100%;
  padding: 0;
  align-items: center;
`
