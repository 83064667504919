//@flow

import * as React from 'react'

import { Box } from '../../../atoms/cards'
import Image from '../../../atoms/image'
import ImageFilter from './imageFilter'

import {
  type SharpImg,
  type PrismicImg,
} from '../../../../types/flow/gatsby-source-prismic-graphql'

type PropTypes = { ...SharpImg, ...PrismicImg }

function Background({ alt, childImageSharp, ...props }: PropTypes) {
  return (
    <Box {...props} bg="primaryShades.60" width="100%">
      <Box position="absolute" top="0" right="0" left="0" bottom="0">
        <Image
          withIEPolyfill
          objectFit="cover"
          objectPosition="50% 50%"
          opacity="0.1"
          alt={alt}
          fluid={childImageSharp && childImageSharp.fluid}
          height="100%"
          width="100%"
        />
      </Box>
      <ImageFilter
        position="absolute"
        top={0}
        right={0}
        bottom={0}
        left={0}
        bg="primaryShades.50"
      />
    </Box>
  )
}

export default Background
