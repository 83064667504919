//@flow

export const ICONS = {
  CLOSE:
    'M4.21 4.39l.08-.1a1 1 0 011.32-.08l.1.08L12 10.6l6.3-6.3a1 1 0 011.31-.08l.1.08a1 1 0 01.08 1.32l-.08.1L13.4 12l6.3 6.3a1 1 0 01.08 1.31l-.08.1a1 1 0 01-1.32.08l-.1-.08L12 13.4l-6.3 6.3a1 1 0 01-1.31.08l-.1-.08a1 1 0 01-.08-1.32l.08-.1L10.6 12l-6.3-6.3a1 1 0 01-.08-1.31l.08-.1-.08.1z',
  MENU:
    'M21 17a1 1 0 010 2H3a1 1 0 010-2zm0-6a1 1 0 010 2H3a1 1 0 010-2zm0-6a1 1 0 010 2H3a1 1 0 110-2z',
  PLANE:
    'M21.7 2.3l.2.3v.7l-6.3 18a1 1 0 01-1.8.1l-3.4-7.6a1 1 0 01-.2-.2l-7.6-3.4a1 1 0 010-1.8l18-6.3h.2c.4-.1.7 0 .9.2zm-3 4.5l-6.4 6.3 2.3 5.2 4-11.5zm-1.5-1.4l-11.5 4 5.2 2.3 6.3-6.3z',
  PIN:
    'M12 1c5 0 9 4.1 9 9.2 0 3-1.6 6-4.3 9a28 28 0 01-3.3 3l-.6.4-.2.2a1 1 0 01-1.2 0l-.2-.2-.6-.4a27.3 27.3 0 01-3.3-3c-2.7-3-4.3-6-4.3-9C3 5.2 7 1 12 1zm0 2a7 7 0 00-7 7.2c0 2.5 1.4 5 3.7 7.6l2.2 2.1.5.4.2.1.4.4.4-.4a26.2 26.2 0 002.9-2.6c2.3-2.5 3.7-5.1 3.7-7.6A7 7 0 0012 3zm0 3a4 4 0 110 8 4 4 0 010-8zm0 2a2 2 0 100 4 2 2 0 000-4z',
  CHEVRON_DOWN:
    'M12 14.35L4.82 7.18a1.16 1.16 0 10-1.64 1.64l8 8c.45.46 1.19.46 1.64 0l8-8a1.16 1.16 0 10-1.64-1.64L12 14.35z',
  PHONE:
    'M19.4 11.27c.39 0 .7.28.75.65v5.56c0 2.6-2.04 4.72-4.62 4.83H8.5a4.84 4.84 0 01-4.84-4.62v-5.67a.75.75 0 011.5-.1v5.56c0 1.78 1.4 3.24 3.16 3.33h7c1.78 0 3.24-1.39 3.33-3.15v-5.64c0-.41.34-.75.76-.75zM8.1 16.05h.18a1.1 1.1 0 11-.24 0h.05zm3.74 0h.19a1.1 1.1 0 11-.24 0h.05zm3.75 0h.19a1.1 1.1 0 11-.24 0h.05zm-7.55-3.4l.07-.01h.17a1.1 1.1 0 11-.24 0zm3.82-.01h.17a1.1 1.1 0 11-.24 0h.07zm3.75 0h.17a1.1 1.1 0 11-.24 0h.07zm3.46-10.25h.03c2.21.5 3.79 2.46 3.79 4.73v.81c0 1.17-.95 2.12-2.12 2.12h-3.4a2.1 2.1 0 01-2.11-1.94l-.01-.24a.61.61 0 00-.61-.55H9.18c-.3 0-.56.23-.6.55l-.02.26a2.11 2.11 0 01-2.1 1.92H3.04A2.11 2.11 0 01.93 7.93v-.8a4.84 4.84 0 013.8-4.73h.02c4.73-.92 9.59-.92 14.32 0zM5.69 3.74l-.67.13a3.34 3.34 0 00-2.59 3.25v.81a.61.61 0 00.62.62h3.4c.32 0 .59-.25.61-.52v-.1c0-1.17.95-2.11 2.12-2.11h5.46c1.09 0 2 .83 2.1 1.94l.01.17c0 .34.27.62.61.62h3.41c.34 0 .62-.28.62-.62v-.8c0-1.56-1.08-2.91-2.6-3.26h-.01a36.15 36.15 0 00-13.1-.13z',
  QUOTE: 'M0 23l4-11H0V2h11v10L5 23H0zm13 0l4-11h-4V2h11v10l-6 11h-5z',
  FACEBOOK:
    'M19.6 14.6c0 .2-.3.4-.6.4h-2c-.3 0-.5.2-.5.5v8c0 .3.2.5.5.5h6c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1H1a1 1 0 0 0-1 1v22c0 .6.4 1 1 1h11c.3 0 .5-.2.5-.5v-8c0-.3-.2-.5-.5-.5h-2a.5.5 0 0 1-.5-.5v-3c0-.3.2-.5.5-.5h2c.3 0 .5-.2.5-.5V9.2c0-3.2 2.5-5.7 5.7-5.7h1.3a.5.5 0 0 1 .5.5v3c0 .3-.2.5-.5.5h-1.3c-1 0-1.7.8-1.7 1.7v1.3c0 .3.2.5.5.5h2.4a.5.5 0 0 1 .5.6l-.3 3z',
  TWITTER:
    'M23.93 6.88a.5.5 0 0 0-.2-.87l-.79-.2a.5.5 0 0 1-.33-.7l.44-.89a.5.5 0 0 0-.58-.7l-2 .56a.5.5 0 0 1-.44-.08 5 5 0 0 0-8 4v.36c0 .13-.1.23-.22.25-2.81.33-5.5-1.1-8.4-4.44a.51.51 0 0 0-.51-.15.5.5 0 0 0-.29.42 7.58 7.58 0 0 0 .46 4.92.25.25 0 0 1-.26.36L1.69 9.5a.5.5 0 0 0-.57.59 5.15 5.15 0 0 0 2.37 3.78.25.25 0 0 1 0 .45l-.53.21a.5.5 0 0 0-.26.69c.6 1.3 1.8 2.23 3.2 2.48a.25.25 0 0 1 0 .47c-1.36.56-2.82.84-4.29.83a.51.51 0 1 0-.2 1 20.06 20.06 0 0 0 8.14 1.93c2.48.04 4.91-.66 7-2A12.5 12.5 0 0 0 22.11 9.5v-.87a.5.5 0 0 1 .18-.38l1.64-1.37z',
  BW_LOGO:
    'M381 134c3-17 3-24 17-24h44c8 0 13 5 13 14l1 211c0 10 12 10 14 0l36-211c2-11 6-14 15-14h69c7 0 10 2 8 15l-58 340c-1 10-7 15-14 15H363c-7 0-13-7-13-12v-40c0-9 5-12 12-12h59c13 0 15-7 7-19l-62-86c-9-14-9-15 0-28l49-71c10-14 9-18-1-18h-49c-14 0-11-9-9-11 9-9 22-32 25-49zM127 447c0-11 12-20 25-20h43c38 0 65-2 87-11s31-31 28-14l-10 62c-2 10-4 16-13 16H139c-9 0-12-7-12-11v-22zM197 0c109 0 123 64 123 98s-13 72-55 89c-3 1-3 6 0 7 30 10 48 45 47 80-1 43-19 97-136 97H21c-23 0-21-12-19-23L56 20C59 5 64 0 77 0h120zm-63 135h38c30 0 43-11 43-27s-9-27-43-27h-28c-7 0-7 4-9 14l-5 32c-1 5-2 8 4 8zm-30 146c-1 6 2 6 9 6h47c26 0 43-10 43-31s-19-31-43-31h-43c-4 0-4 4-5 9l-8 47z',
  TICK:
    'M3.9 12.78a1.14 1.14 0 00-1.57 0c-.44.42-.44 1.1 0 1.52l5.55 5.38c.43.43 1.14.43 1.57 0L21.67 7.84c.44-.42.44-1.1 0-1.52a1.14 1.14 0 00-1.57 0L8.67 17.4 3.9 12.78z',
}
