//@flow
import * as React from 'react'
import styled from 'styled-components'
import { typography, positioning } from '../../helpers'
import { color, system } from 'styled-system'

const Text = styled.span.attrs(
  //$FlowFixMe
  props => ({
    fontFamily: props.fontFamily || 0,
    fontSize: props.fontSize || [1, 1, 2],
    fontWeight: props.fontWeight || 0,
    letterSpacing: props.letterSpacing || 1,
    color: props.color || 'base.50',
  })
)`
  ${positioning};
  ${typography};
  ${color};
  ${system({ textDecoration: true })};
`

type ParagraphProps = {
  lineHeight?: mixed,
  mt?: mixed,
  mb?: mixed,
  my?: mixed,
  children?: React.Node,
}

export function Paragraph({
  lineHeight = 3,
  my = 2,
  children,
  ...props
}: ParagraphProps) {
  return (
    <Text as="p" {...{ lineHeight, my, props }}>
      {children}
    </Text>
  )
}

export default Text
