//@flow
import * as React from 'react'
import styled from 'styled-components'
import { variant, compose } from 'styled-system'
import { positioning } from '../../../helpers'
import { ICONS } from '../../icons'

const defaultStyles = variant({
  variants: {
    primary: {
      fill: 'primaryShades.80',
      height: 2,
    },
    secondary: {
      fill: 'base.60',
      height: 2,
    },
    inverted: {
      fill: 'primaryShades.60',
      height: 2,
    },
    light: {
      fill: 'invertedText.40',
      height: 2,
    },
    'submit-success': {
      fill: 'modes.fern.primaryShades.60',
      height: 2,
    },
  },
})
const hoverStyles = variant({
  prop: 'hoverVariant',
  variants: {
    inverted: {
      fill: 'primaryShades.80',
    },
    logo: {
      fill: 'base.10',
    },
    light: {
      fill: 'invertedText.0',
    },
    'submit-success': {
      fill: 'modes.fern.primaryShades.80',
    },
  },
})
const focusStyles = variant({
  prop: 'hoverVariant',
  variants: {
    inverted: {
      fill: 'primaryShades.80',
    },
    logo: {
      fill: 'base.10',
    },
  },
})

const Svg = styled.svg`
  vertical-align: sub;
  display: inline-block;
  transform-origin: center;
  ${compose(
    defaultStyles,
    focusStyles,
    hoverStyles
  )}
`

const I = styled.i`
  align-items: center;
  font-style: normal;
  position: relative;
  ${positioning};
`

type IconProps = {
  icon: string,
  hover: boolean,
  fill?: string,
  size?: mixed,
  title?: string,
  description?: string,
  viewBox?: string,
  props?: { [string]: mixed },
  variant?: string,
  hover?: mixed,
}

export function Icon({ icon, hover, viewBox, variant, ...props }: IconProps) {
  const symbol = Object.prototype.hasOwnProperty.call(ICONS, icon)
    ? ICONS[icon]
    : icon
  return (
    <I {...props}>
      <Svg
        variant={variant || 'primary'}
        hoverVariant={hover && variant}
        viewBox={viewBox || '0 0 24 24'}
      >
        <path d={symbol} />
      </Svg>
    </I>
  )
}
