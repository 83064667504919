//@flow

import * as React from 'react'
import { RichText as PrismicRichText } from 'prismic-reactjs'
import { Box } from '../../atoms/cards'

import htmlSerializer from './htmlSerializer'

import { type RichTextAry } from '../../../types/flow/prismic'

export const RichText = (props: { ...mixed }) => (
  <PrismicRichText htmlSerializer={htmlSerializer} {...props} />
)

export type RichTextData = {
  gap?: mixed,
  children?: RichTextAry | RichTextAry[],
}

function buildContent(content, gap) {
  if (content) {
    if (Array.isArray(content[0])) {
      return content.map((a, i) => (
        <Box key={i} pt={i > 0 ? gap : 0}>
          <RichText render={a} />
        </Box>
      ))
    }
    return <RichText render={content} />
  }
  return null
}

function RichTextArticle({
  children,
  gap = 0,
  ...props
}: RichTextData): React.Node {
  return <Box {...props}>{buildContent(children, gap)}</Box>
}

export default RichTextArticle
