//@flow

import * as React from 'react'

type MarkerProps = {
  colors: {
    pin: string,
    background: string,
  },
  [string]: mixed,
}

function Marker({ colors: { pin, background }, ...props }: MarkerProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
      <defs>
        <filter
          id="a"
          width="150%"
          height="150%"
          x="-25%"
          y="-25%"
          filterUnits="objectBoundingBox"
        >
          <feGaussianBlur in="SourceGraphic" stdDeviation="2" />
        </filter>
        <path
          id="c"
          d="M12 6c1.2 0 2.2.3 3 .8 1 .5 1.7 1.2 2.2 2.1.5.9.8 2 .8 3.1 0 1.2-.3 2.2-.8 3-.5 1-1.2 1.7-2.1 2.2-.9.5-2 .8-3.1.8-1.2 0-2.2-.3-3-.8a5.6 5.6 0 0 1-2.2-2.1c-.5-.9-.8-2-.8-3.1 0-1.2.3-2.2.8-3C7.3 8 8 7.2 8.9 6.7c.9-.5 2-.8 3.1-.8z"
        />
        <filter
          id="b"
          width="300%"
          height="300%"
          x="-100%"
          y="-100%"
          filterUnits="objectBoundingBox"
        >
          <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="4"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.6 0"
          />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(4 4)">
        <path
          fill={background}
          fillOpacity=".1"
          d="M12 0c2.3 0 4.4.6 6.2 1.6 1.8 1 3.2 2.4 4.2 4.2 1 1.8 1.6 3.9 1.6 6.2 0 2.3-.6 4.4-1.6 6.2-1 1.8-2.4 3.2-4.2 4.2-1.8 1-3.9 1.6-6.2 1.6-2.3 0-4.4-.6-6.2-1.6-1.8-1-3.2-2.4-4.2-4.2C.6 16.4 0 14.3 0 12c0-2.3.6-4.4 1.6-6.2C2.6 4 4 2.6 5.8 1.6 7.6.6 9.7 0 12 0z"
          filter="url(#a)"
        />
        <path
          fill={background}
          fillOpacity=".3"
          d="M12 0c2.3 0 4.4.6 6.2 1.6 1.8 1 3.2 2.4 4.2 4.2 1 1.8 1.6 3.9 1.6 6.2 0 2.3-.6 4.4-1.6 6.2-1 1.8-2.4 3.2-4.2 4.2-1.8 1-3.9 1.6-6.2 1.6-2.3 0-4.4-.6-6.2-1.6-1.8-1-3.2-2.4-4.2-4.2C.6 16.4 0 14.3 0 12c0-2.3.6-4.4 1.6-6.2C2.6 4 4 2.6 5.8 1.6 7.6.6 9.7 0 12 0z"
        />
        <use fill="#000" filter="url(#b)" xlinkHref="#c" />
        <use fill={pin} xlinkHref="#c" />
      </g>
    </svg>
  )
}

export default Marker
