//@flow
import * as React from 'react'
import { themeGet } from '@styled-system/theme-get'
import styled from 'styled-components'
import { Box } from '../cards'

const Svg = styled.svg`
  height: 100%;
  width: auto;
`

const Path = styled.path`
  fill: ${//$FlowFixMe
  (props: { fill: string }) => {
    return themeGet(`colors.${props.fill}`, props.fill)(props)
  }};
`

type Props = {
  fill: string,
  height?: mixed,
}

function SVG(props: Props) {
  return (
    <Box height={props.height} position="relative">
      <Svg viewBox="0 0 600 480">
        <Path
          fill={props.fill}
          d="M381 134c3-17 3-24 17-24h44c8 0 13 5 13 14l1 211c0 10 12 10 14 0l36-211c2-11 6-14 15-14h69c7 0 10 2 8 15l-58 340c-1 10-7 15-14 15H363c-7 0-13-7-13-12v-40c0-9 5-12 12-12h59c13 0 15-7 7-19l-62-86c-9-14-9-15 0-28l49-71c10-14 9-18-1-18h-49c-14 0-11-9-9-11 9-9 22-32 25-49zM127 447c0-11 12-20 25-20h43c38 0 65-2 87-11s31-31 28-14l-10 62c-2 10-4 16-13 16H139c-9 0-12-7-12-11v-22zM197 0c109 0 123 64 123 98s-13 72-55 89c-3 1-3 6 0 7 30 10 48 45 47 80-1 43-19 97-136 97H21c-23 0-21-12-19-23L56 20C59 5 64 0 77 0h120zm-63 135h38c30 0 43-11 43-27s-9-27-43-27h-28c-7 0-7 4-9 14l-5 32c-1 5-2 8 4 8zm-30 146c-1 6 2 6 9 6h47c26 0 43-10 43-31s-19-31-43-31h-43c-4 0-4 4-5 9l-8 47z"
          fillRule="evenodd"
        />
      </Svg>
    </Box>
  )
}

function Logo(props: Props) {
  return <SVG {...props} />
}

Logo.defaultProps = {
  fill: 'black',
}

export default Logo
