//@flow
import { css } from 'styled-components'

//marche super
import marche_woff2 from './marche.woff2'
import marche_woff from './marche.woff'
import marche_ttf from './marche.ttf'
import marche_eot from './marche.eot'
import marcheExt_woff2 from './marche-ext.woff2'
import marcheExt_woff from './marche-ext.woff'
import marcheExt_ttf from './marche-ext.ttf'
import marcheExt_eot from './marche-ext.eot'

//colfax regular
import cf_woff2 from './cf-reg.woff2'
import cf_woff from './cf-reg.woff'
import cf_ttf from './cf-reg.ttf'
import cf_eot from './cf-reg.eot'
import cfExt_woff2 from './cf-reg-ext.woff2'
import cfExt_woff from './cf-reg-ext.woff'
import cfExt_ttf from './cf-reg-ext.ttf'
import cfExt_eot from './cf-reg-ext.eot'

//colfax regular italic
import cfItalic_woff2 from './cf-reg-italic.woff2'
import cfItalic_woff from './cf-reg-italic.woff'
import cfItalic_ttf from './cf-reg-italic.ttf'
import cfItalic_eot from './cf-reg-italic.eot'
import cfItalicExt_woff2 from './cf-reg-italic-ext.woff2'
import cfItalicExt_woff from './cf-reg-italic-ext.woff'
import cfItalicExt_ttf from './cf-reg-italic-ext.ttf'
import cfItalicExt_eot from './cf-reg-italic-ext.eot'

//colfax medium
import cfMed_woff2 from './cf-med.woff2'
import cfMed_woff from './cf-med.woff'
import cfMed_ttf from './cf-med.ttf'
import cfMed_eot from './cf-med.eot'
import cfMedExt_woff2 from './cf-med-ext.woff2'
import cfMedExt_woff from './cf-med-ext.woff'
import cfMedExt_ttf from './cf-med-ext.ttf'
import cfMedExt_eot from './cf-med-ext.eot'

//colfax medium italic
import cfMedItalic_woff2 from './cf-med-italic.woff2'
import cfMedItalic_woff from './cf-med-italic.woff'
import cfMedItalic_ttf from './cf-med-italic.ttf'
import cfMedItalic_eot from './cf-med-italic.eot'
import cfMedItalicExt_woff2 from './cf-med-italic-ext.woff2'
import cfMedItalicExt_woff from './cf-med-italic-ext.woff'
import cfMedItalicExt_ttf from './cf-med-italic-ext.ttf'
import cfMedItalicExt_eot from './cf-med-italic-ext.eot'

//colfax bold
import cfBold_woff2 from './cf-bold.woff2'
import cfBold_woff from './cf-bold.woff'
import cfBold_ttf from './cf-bold.ttf'
import cfBold_eot from './cf-bold.eot'
import cfBoldExt_woff2 from './cf-bold-ext.woff2'
import cfBoldExt_woff from './cf-bold-ext.woff'
import cfBoldExt_ttf from './cf-bold-ext.ttf'
import cfBoldExt_eot from './cf-bold-ext.eot'

//colfax bold italic
import cfBoldItalic_woff2 from './cf-bold-italic.woff2'
import cfBoldItalic_woff from './cf-bold-italic.woff'
import cfBoldItalic_ttf from './cf-bold-italic.ttf'
import cfBoldItalic_eot from './cf-bold-italic.eot'
import cfBoldItalicExt_woff2 from './cf-bold-italic-ext.woff2'
import cfBoldItalicExt_woff from './cf-bold-italic-ext.woff'
import cfBoldItalicExt_ttf from './cf-bold-italic-ext.ttf'
import cfBoldItalicExt_eot from './cf-bold-italic-ext.eot'

export default css`
/* Marche */
@font-face {
  font-family: 'Marche';
  font-style: normal;
  font-weight: 700;
  font-display: auto;
  src: local('Marche'),
  url('${marche_woff2}') format('woff2'),
  url('${marche_woff}') format('woff'),
  url('${marche_ttf}') format('ttf'),
  url('${marche_eot}') format('eot');
  unicode-range: U+0020-007A;
}
@font-face {
  font-family: 'Marche';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Marche'),
  url('${marcheExt_woff2}') format('woff2'),
  url('${marcheExt_woff}') format('woff'),
  url('${marcheExt_ttf}') format('ttf'),
  url('${marcheExt_eot}') format('eot');
  unicode-range: U+007B-007E,U+00A0-00FF,U+2013-201D,U+00A3;
}
/* Colfax Regular */
@font-face {
  font-family: 'Colfax';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: local('Colfax-Regular'),
  url('${cf_woff2}') format('woff2'),
  url('${cf_woff}') format('woff'),
  url('${cf_ttf}') format('ttf'),
  url('${cf_eot}') format('eot');
  unicode-range: U+0020-007A;
}
@font-face {
  font-family: 'Colfax';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Colfax-Regular'),
  url('${cfExt_woff2}') format('woff2'),
  url('${cfExt_woff}') format('woff'),
  url('${cfExt_ttf}') format('ttf'),
  url('${cfExt_eot}') format('eot');
  unicode-range: U+007B-007E,U+00A0-00FF,U+2013-201D,U+00A3;
}
/* Colfax Regular Italic */
@font-face {
  font-family: 'Colfax';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Colfax-RegularItalic'),
  url('${cfItalic_woff2}') format('woff2'),
  url('${cfItalic_woff}') format('woff'),
  url('${cfItalic_ttf}') format('ttf'),
  url('${cfItalic_eot}') format('eot');
  unicode-range: U+0020-007A;
}
@font-face {
  font-family: 'Colfax';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Colfax-RegularItalic'),
  url('${cfItalicExt_woff2}') format('woff2'),
  url('${cfItalicExt_woff}') format('woff'),
  url('${cfItalicExt_ttf}') format('ttf'),
  url('${cfItalicExt_eot}') format('eot');
  unicode-range: U+007B-007E,U+00A0-00FF,U+2013-201D,U+00A3;
}
/* Colfax Medium */
@font-face {
  font-family: 'Colfax';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Colfax-Medium'),
  url('${cfMed_woff2}') format('woff2'),
  url('${cfMed_woff}') format('woff'),
  url('${cfMed_ttf}') format('ttf'),
  url('${cfMed_eot}') format('eot');
  unicode-range: U+0020-007A;
}
@font-face {
  font-family: 'Colfax';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Colfax-Medium'),
  url('${cfMedExt_woff2}') format('woff2'),
  url('${cfMedExt_woff}') format('woff'),
  url('${cfMedExt_ttf}') format('ttf'),
  url('${cfMedExt_eot}') format('eot');
  unicode-range: U+007B-007E,U+00A0-00FF,U+2013-201D,U+00A3;
}
/* Colfax Medium Italic */
@font-face {
  font-family: 'Colfax';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: local('Colfax-MediumItalic'),
  url('${cfMedItalic_woff2}') format('woff2'),
  url('${cfMedItalic_woff}') format('woff'),
  url('${cfMedItalic_ttf}') format('ttf'),
  url('${cfMedItalic_eot}') format('eot');
  unicode-range: U+0020-007A;
}
@font-face {
  font-family: 'Colfax';-Bold
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: local('Colfax-MediumItalic'),
  url('${cfMedItalicExt_woff2}') format('woff2'),
  url('${cfMedItalicExt_woff}') format('woff'),
  url('${cfMedItalicExt_ttf}') format('ttf'),
  url('${cfMedItalicExt_eot}') format('eot');
  unicode-range: U+007B-007E,U+00A0-00FF,U+2013-201D,U+00A3;
}
/* Colfax Bold */
@font-face {
  font-family: 'Colfax';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Colfax-Bold'),
  url('${cfBold_woff2}') format('woff2'),
  url('${cfBold_woff}') format('woff'),
  url('${cfBold_ttf}') format('ttf'),
  url('${cfBold_eot}') format('eot');
  unicode-range: U+0020-007A;
}
@font-face {
  font-family: 'Colfax';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Colfax-Bold'),
  url('${cfBoldExt_woff2}') format('woff2'),
  url('${cfBoldExt_woff}') format('woff'),
  url('${cfBoldExt_ttf}') format('ttf'),
  url('${cfBoldExt_eot}') format('eot');
  unicode-range: U+007B-007E,U+00A0-00FF,U+2013-201D,U+00A3;
}
/* Colfax Bold Italic */
@font-face {
  font-family: 'Colfax';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('Colfax-BoldItalic'),
  url('${cfBoldItalic_woff2}') format('woff2'),
  url('${cfBoldItalic_woff}') format('woff'),
  url('${cfBoldItalic_ttf}') format('ttf'),
  url('${cfBoldItalic_eot}') format('eot');
  unicode-range: U+0020-007A;
}
@font-face {
  font-family: 'Colfax';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('Colfax-BoldItalic'),
  url('${cfBoldItalicExt_woff2}') format('woff2'),
  url('${cfBoldItalicExt_woff}') format('woff'),
  url('${cfBoldItalicExt_ttf}') format('ttf'),
  url('${cfBoldItalicExt_eot}') format('eot');
  unicode-range: U+007B-007E,U+00A0-00FF,U+2013-201D,U+00A3;
}
`
