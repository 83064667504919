//@flow
import * as React from 'react'
import Icon from '../../icons'
import styled from 'styled-components'
import { system } from 'styled-system'
import { positioning, styling } from '../../../helpers/styledBlocks'

const Background = styled.div`
  position: relative;
  ${positioning};
  ${styling};
  ${system({ textDecoration: true })};
`

type PropTypes = {
  children: React.Node,
  symbol: string,
  viewBox?: string,
  iconLeft?: boolean,
  iconRight?: boolean,
  iconTitle?: string,
  description?: string,
  fill?: string,
  props?: {
    [string]: mixed,
  },
  spaceBetween: mixed,
}

function WithIcon({
  iconLeft,
  iconRight,
  iconTitle,
  description,
  children,
  symbol,
  viewBox,
  fill,
  spaceBetween,
  ...props
}: PropTypes) {
  const I = (
    <Icon
      size={'1.25em'}
      title={iconTitle}
      description={description}
      symbol={symbol ? symbol : null}
      viewBox={viewBox}
      fill={fill}
      ml={iconRight && children && spaceBetween}
      mr={iconLeft && children && spaceBetween}
      bottom="0.125em"
    />
  )
  return (
    <Background {...props}>
      {iconLeft && symbol && I}
      {children}
      {iconRight && symbol && I}
    </Background>
  )
}

WithIcon.defaultProps = {
  spaceBetween: [2],
}

export default WithIcon
