//@flow

import * as React from 'react'
import { Flex } from '../../atoms/cards'
import LocationList from './locationList'

import { type RichTextAry } from '../../../types/prismicSchema'

export type LocationBoxData = {
  locations: string[],
  listHeading: RichTextAry,
}

function LocationBox({ listHeading, locations, ...props }: LocationBoxData) {
  return (
    <Flex
      {...props}
      style={{
        userSelect: 'all',
        pointerEvents: 'all',
      }}
      pt={[1]}
      pb={[4]}
      px={[4, 4, 5, 5, 5]}
      borderRadius={[3]}
      bg="white"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="flex-start"
      boxShadow="p2"
    >
      <LocationList heading={listHeading} locations={locations} />
    </Flex>
  )
}

export default LocationBox
