//@flow

exports.space = [
  '0px',
  '0.5rem',
  '0.75rem',
  '1rem',
  '1.5rem',
  '2rem',
  '3rem', //6
  '4.5rem',
  '6rem',
  '8rem',
  '10rem',
  '12rem',
  '15rem',
]
