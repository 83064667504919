//@flow

exports.shadows = {
  l2:
    '0 1px 8px 0 rgba(15, 27, 61, 0.04), 0 1px 3px -1px rgba(0,0,0,0.08), 0 6px 16px -8px rgba(0,0,0,0.24)',
  modes: {
    main: {
      p1:
        '0 1px 5px 0 rgba(8, 15, 58, 0.24), 0 2px 3px -2px rgba(0, 0, 0, 0.40)',
      p2:
        '0 2px 4px 0 rgba(15, 27, 61, 0.10), 0 2px 4px 0 rgba(0, 0, 0, 0.08), 0 6px 16px -8px rgba(0, 0, 0, 0.24)',
      s1:
        '0 2px 5px -3px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 149, 149, 0.16), 0 2px 3px -2px rgba(0, 0, 0, 0.40)',
    },
    ash: {
      p1:
        '0 2px 3px -2px rgba(0, 0, 0, 0.40), 0 1px 5px 0 rgba(43, 43, 43, 0.24)',
      p2:
        '0 2px 4px 0 rgba(65, 65, 65, 0.10), 0 2px 4px 0 rgba(0, 0, 0, 0.08), 0 6px 16px -8px rgba(0, 0, 0, 0.24)',
      s1:
        '0 2px 5px -3px rgba(0, 0, 0, 0.12), 0 2px 3px -2px rgba(239, 97, 15, 0.16), 0 1px 5px 0 rgba(43, 43, 43, 0.24)',
    },
    teal: {
      p1:
        '0 1px 5px 0 rgba(51, 68, 98, 0.24), 0 2px 3px -2px rgba(0, 0, 0, 0.40)',
      p2:
        '0 2px 4px 0 rgba(51, 68, 98, 0.10), 0 2px 4px 0 rgba(0, 0, 0, 0.08), 0 6px 16px -8px rgba(0, 0, 0, 0.24)',
      s1:
        '0 2px 5px -3px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(32, 112, 152, 0.16), 0 2px 3px -2px rgba(0, 0, 0, 0.40)',
    },
    fern: {
      p1:
        '0 1px 5px 0 rgba(26, 79, 67, 0.24), 0 2px 3px -2px rgba(0, 0, 0, 0.40)',
      p2:
        '0 2px 4px 0 rgba(26, 79, 67, 0.10), 0 2px 4px 0 rgba(0, 0, 0, 0.08), 0 6px 16px -8px rgba(0, 0, 0, 0.24)',
      s1:
        '0 2px 5px -3px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(7, 111, 91, 0.16), 0 2px 3px -2px rgba(0, 0, 0, 0.40)',
    },
    flame: {
      p1:
        '0 1px 5px 0 rgba(121, 80, 22, 0.36), 0 2px 3px -2px rgba(0, 0, 0, 0.40)',
      p2:
        '0 2px 4px 0 rgba(121, 80, 22, 0.10), 0 2px 4px 0 rgba(0, 0, 0, 0.08), 0 6px 16px -8px rgba(0, 0, 0, 0.24)',
      s1:
        '0 2px 5px -3px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(188, 122, 15, 0.16), 0 2px 3px -2px rgba(0, 0, 0, 0.40)',
    },
    fawn: {
      p1:
        '0 1px 5px 0 rgba(53, 68, 53, 0.24), 0 2px 3px -2px rgba(0, 0, 0, 0.40)',
      p2:
        '0 2px 4px 0 rgba(53, 68, 53, 0.10), 0 2px 4px 0 rgba(0, 0, 0, 0.08), 0 6px 16px -8px rgba(0, 0, 0, 0.24)',
      s1:
        '0 2px 5px -3px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(37, 155, 137, 0.24), 0 2px 3px -2px rgba(0, 0, 0, 0.40)',
    },
    blush: {
      p1:
        '0 1px 5px 0 rgba(60, 50, 90, 0.24), 0 2px 3px -2px rgba(0, 0, 0, 0.40)',
      p2:
        '0 2px 4px 0 rgba(60, 50, 90, 0.10), 0 2px 4px 0 rgba(0, 0, 0, 0.08), 0 6px 16px -8px rgba(0, 0, 0, 0.24)',
      s1:
        '0 2px 5px -3px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(98, 51, 148, 0.16), 0 2px 3px -2px rgba(0, 0, 0, 0.40)',
    },
    brick: {
      p1:
        '0 1px 5px 0 rgba(85, 21, 44, 0.24), 0 2px 3px -2px rgba(0, 0, 0, 0.40)',
      p2:
        '0 2px 4px 0 rgba(137, 25, 38, 0.10), 0 2px 4px 0 rgba(0, 0, 0, 0.08), 0 6px 16px -8px rgba(0, 0, 0, 0.24)',
      s1:
        '0 2px 5px -3px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(137, 25, 38, 0.16), 0 2px 3px -2px rgba(0, 0, 0, 0.40)',
    },
  },
}
