//@flow
import * as React from 'react'
import { createPortal } from 'react-dom'

import { NotificationContext } from '../../../shared/context'
import { BreakpointContext } from '../../../shared/context'

import { Frame } from './frame'
import { Toast, type ToastProps } from './toast'
import { GdprPopup, type GdprProps } from './gdprPopup'

type NotificationProps = {
  children?: React.Node,
}

type Notification = ToastProps | GdprProps

function notificationReducer(
  state: {
    key: number,
    notifications: Notification[],
  },
  {
    type,
    key,
    notification,
  }: {
    type: string,
    key?: number,
    notification?: Notification,
  }
) {
  switch (type) {
    case 'ADD_NOTIFICATION':
      return {
        key: state.key + 1,
        notifications: [
          ...state.notifications,
          //$FlowFixMe
          { key: state.key, ...notification },
        ],
      }
    case 'REMOVE_NOTIFICATION': {
      return {
        ...state,
        notifications: state.notifications.filter(s => s.key !== key),
      }
    }
    default:
      throw Error()
  }
}

const initialState = {
  key: 0,
  notifications: [],
}

export function Notifications({ children }: NotificationProps) {
  const [target, setTarget] = React.useState()
  const [{ notifications }, dispatch] = React.useReducer(
    notificationReducer,
    initialState
  )
  const breakpoint = React.useContext(BreakpointContext)

  const addNotification = notification =>
    dispatch({ type: 'ADD_NOTIFICATION', notification })

  const removeNotification = key =>
    dispatch({ type: 'REMOVE_NOTIFICATION', key })

  const filteredNotfifications = notifications.reduce(
    (acc, n) => {
      switch (n.type) {
        case 'toast':
          return {
            ...acc,
            toasts: [...acc.toasts, n],
          }
        case 'gdpr':
          return {
            ...acc,
            gdpr: [...acc.gdpr, n],
          }
        default:
          return acc
      }
    },
    {
      toasts: [],
      gdpr: [],
    }
  )

  const { toasts, gdpr } = filteredNotfifications

  const portalElements = (
    <Frame
      toasts={
        toasts &&
        toasts.map(({ key, body, ...props }) => (
          <Toast
            key={key}
            mb={[1, 1, 2, 2, 3]}
            mr={[1, 1, 2, 2, 3]}
            ml={[1]}
            breakpoint={breakpoint}
            body={body}
            unmount={() => removeNotification(key)}
            {...props}
          />
        ))
      }
      gdpr={
        gdpr &&
        gdpr.map(({ key, body, ...props }: GdprProps) => (
          <GdprPopup
            key={key}
            breakpoint={breakpoint}
            unmount={() => removeNotification(key)}
            body={body}
            {...props}
          />
        ))
      }
    />
  )
  const portal =
    target && notifications.length ? createPortal(portalElements, target) : null

  React.useEffect(() => {
    const element = document && document.createElement('div')
    if (document) {
      if (!target) {
        document.body && document.body.appendChild(element)
        setTarget(element)
      }
    }
    return () => {
      !notifications && element.remove()
    }
  }, [notifications, target])

  return (
    <NotificationContext.Provider key="1" value={{ addNotification }}>
      {[portal, children]}
    </NotificationContext.Provider>
  )
}
