//@flow
import * as React from 'react'
import { ThemeProvider } from 'styled-components'
import { makeTheme } from '../../theme'
import { GlobalStyles } from '../../shared/global-styles'
import { PageThemeContext } from '../../shared/context/themeContext'
import { Notifications } from '../organisms/notifications'
import BreakpointContextProvider from './helpers/breakpointContextProvider'
import Navigation from '../organisms/mainNavigation'
import Footer from '../organisms/footer'
import Background from '../containers/background'
import LayoutBox from '../containers/layoutBox'
import { Box } from '../atoms/cards'
import { MobileMenu } from '../organisms/mobileMenu'
import { SeoData, type PageMeta } from '../containers/seoData'

import marche from '../../fonts/marche.woff2'
import colfax from '../../fonts/cf-reg.woff2'

type ViewProps = {
  children?: React.Node,
}

function View({ children }: ViewProps) {
  const [mobileMenuActive, setMobileMenu] = React.useState(false)
  const openMenu = () => setMobileMenu(true)
  const closeMenu = () => setMobileMenu(false)

  return (
    <>
      <Background
        bg="transparent"
        position="absolute"
        left="0px"
        right="0px"
        justifyContent="center"
      >
        <LayoutBox>
          <Navigation
            mobileNavIsActive={mobileMenuActive}
            openMenu={openMenu}
            py={[3, 3, 3, 4]}
          />
        </LayoutBox>
      </Background>
      <Box bg="base.10" as="main">
        {children}
      </Box>
      <Footer />
      <MobileMenu isActive={mobileMenuActive} close={closeMenu} />
    </>
  )
}

function Layout({
  children,
  seoData,
  path,
  themeName = 'main',
}: {
  children?: React.Node,
  seoData: PageMeta,
  path: string,
  themeName?: string,
}) {
  const theme = makeTheme(themeName)
  React.useEffect(() => {
    window.prismic = {
      endpoint: 'https://bwpropertycare.cdn.prismic.io/api/v2',
    }
  }, [])
  return (
    <PageThemeContext.Provider value={theme} key="page">
      <GlobalStyles />
      <ThemeProvider theme={theme}>
        <BreakpointContextProvider>
          <Notifications>
            <SeoData
              data={seoData}
              path={path}
              meta={[{ name: 'format-detection', content: 'telephone=no' }]}
            >
              <link
                rel="preload"
                href={marche}
                as="font"
                type="font/woff2"
                crossOrigin
              />
              <link
                rel="preload"
                href={colfax}
                as="font"
                type="font/woff2"
                crossOrigin
              />
              <html lang="en" />
            </SeoData>
            <View>{children}</View>
          </Notifications>
        </BreakpointContextProvider>
      </ThemeProvider>
    </PageThemeContext.Provider>
  )
}

export default Layout
