//@flow

import {
  type RichTextAry,
  type PrismicImg,
  type SharpImg,
  type PrismicLink,
  type ColorTheme,
} from '../../../../types/flow/gatsby-source-prismic-graphql'
import type { ServiceGridType } from '../serviceGrid'

export type ServiceGridData = {
  type: 'service_grid',
  fields: {
    description: RichTextAry,
    service_name: string,
    service_image: PrismicImg,
    service_imageSharp: SharpImg,
    link: {
      ...PrismicLink,
      theme: {
        _meta: {
          uid: ColorTheme,
        },
      },
    },
  }[],
  primary: { heading: RichTextAry },
}

export function modelServiceGrid({
  primary,
  fields,
}: ServiceGridData): ServiceGridType {
  return {
    heading: primary.heading,
    serviceGridItems: fields.map(f => {
      const link = f.link
      return {
        sharpImage: { ...f.service_image, ...f.service_imageSharp },
        serviceName: f.service_name,
        link:
          Object.prototype.hasOwnProperty.call(link, '_meta') && link._meta.uid,
        theme: link.theme._meta.uid,
      }
    }),
  }
}
