//@flow

import styled from 'styled-components'
import { positioning } from '../../../helpers'

const Ul = styled.ul`
  margin: 0;
  padding: 0;
  justify-content: center;
  ${positioning};
`

export default Ul
