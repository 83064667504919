//@flow

import * as React from 'react'
import Ul from './styles/ul'
import Li from './styles/li'
import { Button } from '../../atoms/buttons/button-new/button'

type PropTypes = {
  children?: React.Node,
}

function DesktopNavItems(props: PropTypes) {
  return (
    <Ul {...props}>
      {/*<Li>
        <Button px={[2]} py={[1]}>
          <WithIcon
            iconRight
            spaceBetween={[1]}
            symbol={ICONS.CHEVRON_DOWN}
            fill="primaryA11y"
          >
            <Text color="primaryA11y">Services</Text>
          </WithIcon>
        </Button>
      </Li>
      <Li ml={[3]}>
        <Button to="/" px={[2]} py={[3]}>
          <Text color="primaryA11y">About</Text>
        </Button>
      </Li>*/}
      <Li ml={[3]}>
        <Button
          to="/contact"
          typography="ui"
          buttonStyle="ghost"
          focusRing
          variant="inverted"
          hoverEffect
        >
          Contact Us
        </Button>
      </Li>
    </Ul>
  )
}

export default DesktopNavItems
