//@flow

import * as React from 'react'

import ServiceGrid, {
  modelServiceGrid,
  type ServiceGridData,
} from '../organisms/serviceGrid'
import MapSection, {
  modelLocationSlice,
  type MapSectionData,
} from '../organisms/mapSection'
import HighlightGrid, {
  modelHighlightsData,
  type HighlightsData,
} from '../organisms/highlightGrid'
import PromotionLrg, {
  modelPromotionLrgData,
  type PromotionLrgData,
} from '../organisms/promotionLrg'
import Cta, { modelPageCtaData, type PageCtaData } from '../organisms/pageCta'

export type SliceData =
  | { ...ServiceGridData }
  | { ...HighlightsData }
  | { ...MapSectionData }
  | { ...PromotionLrgData }
  | { ...PageCtaData }

export type SliceZoneData = SliceData[]

function getSlice(slice: SliceData, i?: string | number, props?: {}) {
  if (slice && slice.type) {
    if (slice.type === 'service_grid') {
      //$FlowFixMe
      return <ServiceGrid key={i} data={modelServiceGrid(slice)} />
    }
    if (slice.type === 'locations') {
      //$FlowFixMe
      return <MapSection {...props} key={i} data={modelLocationSlice(slice)} />
    }
    if (slice.type === 'highlights_row') {
      return (
        <HighlightGrid
          key={i}
          //$FlowFixMe
          data={modelHighlightsData(slice)}
        ></HighlightGrid>
      )
    }
    if (slice.type === 'promotion') {
      //$FlowFixMe
      return <PromotionLrg key={i} data={modelPromotionLrgData(slice)} />
    }
    if (slice.type === 'cta') {
      //$FlowFixMe
      return <Cta key={i} data={modelPageCtaData(slice)}></Cta>
    }
    return null
  }
  return null
}

function SliceZone({
  children,
  ...props
}: {
  children: SliceZoneData | SliceData,
}) {
  let slices
  if (Array.isArray(children)) {
    slices = children.map((slice, i) => getSlice(slice, i, props))
  } else {
    slices = getSlice(children)
  }
  return <>{slices}</>
}

export default SliceZone
