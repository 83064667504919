//@flow

import * as React from 'react'
import { useInterval } from '../../../shared/hooks'
import { motion, AnimatePresence } from 'framer-motion'
import { wrap } from '@popmotion/popcorn'
import { Guild, Chas, Wykamol } from '../../../images/SVG/Badge'
import { Ul } from './styles'
import { Box } from '../../atoms/cards'

const badgeAry = [Guild, Chas, Wykamol].map((Component, i) => (
  <Component height="100%" key={i} />
))

const variants = {
  enter: {
    x: 200,
    opacity: 0.8,
    rotateY: '-60deg',
  },
  center: {
    x: 0,
    opacity: 1,
    rotateY: 0,
    transition: {
      delay: 0.75,
      duration: 1,
      ease: 'easeOut',
    },
  },
  exit: {
    x: -200,
    opacity: 0.8,
    rotateY: '60deg',
    transition: {
      duration: 1,
      ease: 'easeIn',
    },
  },
}

export function BadgeSlider() {
  const [currentBadge, setBadge] = React.useState(0)

  // We only have 3 images, but we paginate them absolutely (ie 1, 2, 3, 4, 5...) and
  // then wrap that within 0-2 to find our image ID in the array below. By passing an
  // absolute page index as the `motion` component's `key` prop, `AnimatePresence` will
  // detect it as an entirely new image. So you can infinitely paginate as few as 1 images.

  const badgeIndex = wrap(0, badgeAry.length, currentBadge)

  const paginate = () => {
    setBadge(currentBadge + 1)
  }
  useInterval(paginate, 4000)

  return (
    <Ul>
      <AnimatePresence initial={false}>
        <Box
          as={motion.li}
          key={currentBadge}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          position="absolute"
          top={2}
          right={2}
          bottom={2}
          left={2}
          css={`
            perspective: 400px;
            will-change: opacity, transform, scale;
          `}
        >
          {badgeAry[badgeIndex]}
        </Box>
      </AnimatePresence>
    </Ul>
  )
}
