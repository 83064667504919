import { createGlobalStyle } from 'styled-components'
import { normalize } from 'polished'
import fontFaces from '../fonts/font-face'

export const GlobalStyles = createGlobalStyle`
  html {
  ${fontFaces};
    ${normalize()};
    text-rendering: op­ti­mizeLeg­i­bil­ity;
    font-size: 100%;
    line-height: 1;
    box-sizing: border-box;
  }
  body {
    height: 100%;
    padding: 0px;
    margin: 0px;
    font-kerning: normal;
    font-variant-ligatures: common-ligatures contextual;
    font-feature-settings: "kern", "liga", "clig", "calt";
  }
  button {
    display: inline-block;
    border: none;
    margin: 0;
    text-decoration: none;
    font-size: 1em;
    cursor: pointer;
    text-align: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: none;
    &::-moz-focus-inner {
      border: 0 !important;
    }
  }
`
