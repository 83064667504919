//@flow
import * as React from 'react'
import styled from 'styled-components'
import { system } from 'styled-system'
import { styling, positioning, typography } from '../../helpers'

const Svg = styled.svg`
  display: inline-block;
  vertical-align: middle;
  transform-origin: center;
  ${system({
    size: {
      property: 'height',
      scale: 'sizes',
    },
    fill: {
      property: 'fill',
      scale: 'colors',
    },
  })}
`

const I = styled.i`
  display: inline-block;
  font-style: normal;
  position: relative;
  ${typography};
  ${styling};
  ${positioning};
`

type IconProps = {
  symbol: string | null,
  fill?: string,
  size?: mixed,
  title?: string,
  description?: string,
  viewBox?: string,
  props?: { [string]: mixed },
}

function Icon({
  title,
  symbol,
  size,
  fill,
  description,
  viewBox,
  ...props
}: IconProps) {
  return (
    <I {...props}>
      <Svg
        size={size || 1}
        fill={fill || 'black'}
        viewBox={viewBox || '0 0 24 24'}
      >
        {title && <title>{title}</title>}
        {description && <description>{description}</description>}
        <path d={symbol} />
      </Svg>
    </I>
  )
}

export default Icon
