//@flow

import * as React from 'react'
import { Box } from '../cards'
import Marker from './markers/marker'
import { useScale } from '../../../shared/hooks'
import mapStyles from './styles/mapStyles'
import emToPx from '../../../utils/emToPx'
import mapColorConfig from '../../../shared/helpers/mapColorConfig'

import {
  GoogleMap,
  useLoadScript,
  OverlayView,
  useGoogleMap,
} from '@react-google-maps/api'

type MarkerType = {
  lat: number,
  lng: number,
}

type MarkersType = MarkerType[]

const MarkerColorConfig = {
  pin: 'secondaryShades.40',
  background: 'primaryShades.40',
}

function MapMarker(props: { coords: MarkerType }) {
  const { lat, lng } = props.coords
  const markerColors = mapColorConfig(MarkerColorConfig)
  return (
    <OverlayView
      position={{
        lat,
        lng,
      }}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
      <Box
        bg="transparent"
        style={{ transform: 'translate(-50%,-50%)' }}
        height={[3, 3, 4]}
        width={[3, 3, 4]}
      >
        <Marker colors={markerColors}></Marker>
      </Box>
    </OverlayView>
  )
}

type PanObject = {
  x?: string | number | (string | number)[],
  y?: string | number | (string | number)[],
}

function RenderMarkers({
  coords,
  offsetMap,
}: {
  coords: MarkersType,
  offsetMap: PanObject,
}) {
  const map = useGoogleMap()
  const { x, y } = offsetMap
  const [px, py] = useScale('space')(x, y).map(m => emToPx(m))
  React.useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds()
      coords.forEach(c => bounds.extend(c))
      map.fitBounds(bounds, { bottom: '40' })
      map.panBy(px, py)
    }
  })
  return (
    <>
      {coords.map((c, i) => (
        <MapMarker key={i} coords={c} />
      ))}
    </>
  )
}

function RenderMap({
  markerPositions,
  ...props
}: {
  markerPositions: MarkersType,
  offsetMap: PanObject,
}) {
  return (
    <GoogleMap
      tabIndex="-1"
      mapContainerStyle={{
        height: '100%',
        width: '100%',
        position: 'absolute',
      }}
      options={{
        zoomControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        panControl: false,
        draggable: false,
        draggableCursor: 'default',
        zoom: 5,
        styles: mapStyles(),
      }}
    >
      <RenderMarkers coords={markerPositions} {...props} />
    </GoogleMap>
  )
}

export type MapProps = {
  markerPositions: MarkersType,
  offsetMap: PanObject,
}

function Map({ markerPositions, offsetMap, ...props }: MapProps) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.GATSBY_GOOGLE_API_KEY,
    id: 'script-loader',
    preventGoogleFontsLoading: true,
  })

  let innerContent
  if (loadError) {
    innerContent = 'Sorry, there was an error loading the map'
  } else if (isLoaded) {
    innerContent = (
      <RenderMap
        offsetMap={offsetMap}
        markerPositions={markerPositions}
        {...props}
      />
    )
  } else {
    innerContent = <Box bg="primary"></Box>
  }
  return <Box {...props}>{innerContent}</Box>
}

export default Map
