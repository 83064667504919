import styled from 'styled-components'
import { positioning, styling, typography } from '../../helpers'
import { flexbox } from 'styled-system'

export const Span = styled('span')(positioning, styling, typography)

export const Box = styled('div')(
  {
    boxSizing: 'border-box',
    padding: 0,
    margin: 0,
  },
  positioning,
  styling,
  typography
)

export const Flex = styled(Box)(
  {
    display: 'flex',
  },
  flexbox
)
