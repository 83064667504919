//@flow

import * as React from 'react'
import Nav from './styles/nav'
import { Span } from '../../atoms/cards'
import DesktopTabs from '../../molecules/desktopNav'
import { BreakpointContext } from '../../../shared/context'
import { Ul, Li } from './styles/listItem'
import Logo from '../../atoms/logo'
import { Button } from '../../atoms/buttons/button-new/button'

type PropTypes = {
  openMenu: (*) => void,
  props?: { [string]: mixed },
}

function MainNav({ openMenu, ...props }: PropTypes) {
  const breakpoint = React.useContext(BreakpointContext)
  return (
    <Nav as="nav" {...props}>
      <Ul>
        <Li flex="1 1 auto">
          <Button
            px={[0]}
            py={[0]}
            to="/"
            buttonStyle="ghost"
            focusRing={-2}
            render={({ hover }) => (
              <Logo
                width="auto"
                height={[4, 4, 4, 5, 5]}
                fill={hover ? 'base.0' : 'base.10'}
              />
            )}
          />
        </Li>
        <Li
          display={['none', 'none', 'block']}
          mx="auto"
          justifyContent="flex-end"
        >
          <DesktopTabs
            width="auto"
            display={['none', 'none', 'flex']}
            aria-hidden={breakpoint >= 2 ? 'false' : 'true'}
          />
        </Li>

        <Li>
          <Button
            icon="PHONE"
            typography="ui"
            buttonStyle="solid"
            variant="inverted"
            href="tel:+441603860086"
            px={[3, 3, 4]}
            ml={[0, 0, 4, 4, 5]}
            spaceBetween={[0, 1, 2]}
          >
            <Span
              display={['none', 'inline']}
              aria-hidden={breakpoint >= 1 ? 'false' : 'true'}
            >
              01603 860086
            </Span>
          </Button>
        </Li>
        <Li>
          <Button
            display={['inline-block', 'inline-block', 'none']}
            icon="MENU"
            onClick={openMenu}
            buttonStyle="solid"
            variant="secondary"
            ml={[2, 2, 2, 0]}
            px={3}
          />
        </Li>
      </Ul>
    </Nav>
  )
}

export default MainNav
