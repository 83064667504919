//@flow
import * as React from 'react'
import type { StyledSystemProp } from '../../types/flow/styled-system'
import { Box } from '../atoms/cards'

type PropTypes = {
  children?: React.Node,
  mx?: StyledSystemProp,
  flex?: StyledSystemProp,
  props?: { [string]: mixed },
}

function LayoutBox({
  children,
  mx = [3, 3, 4, 5],
  flex = '1 1 10000px',
  ...props
}: PropTypes) {
  const defaults = { mx, flex }
  return (
    <Box maxWidth={15} zIndex={1} {...defaults} {...props}>
      {children}
    </Box>
  )
}

export default LayoutBox
