//@flow

import styled from 'styled-components'
import { Box } from '../../../atoms/cards'

const ImageFilter = styled(Box)`
  ${props => {
    return props.fallback && `opacity: 0.5;`
  }};
  mix-blend-mode: color;
`

export default ImageFilter
