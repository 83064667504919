//@flow
import * as React from 'react'

import Background from '../../containers/background'
import LayoutBox from '../../containers/layoutBox'
import ServiceCard, { type ServiceGridItem } from '../../molecules/serviceCard'
import FlexColumnGrid from '../../containers/flexColumnGrid'
import { Flex, Box } from '../../atoms/cards'
import { RichText } from '../../molecules/richTextArticle'

import { type RichTextAry } from '../../../types/flow/gatsby-source-prismic-graphql'

export type ServiceGridType = {
  heading: RichTextAry,
  serviceGridItems: ServiceGridItem[],
}

function ServiceGrid({ data }: { data: ServiceGridType }) {
  const serviceCards = data.serviceGridItems.map((s, i) => {
    const { serviceName, sharpImage, /*description ,*/ link, theme } = s
    return (
      <ServiceCard
        link={link}
        sharpImage={sharpImage}
        serviceName={serviceName}
        key={i}
        width="100%"
        theme={theme}
      />
    )
  })
  return (
    <Background pt={[7, 7, 6]} pb={[6, 6, 7]} justifyContent="center">
      <LayoutBox>
        <Box
          as="section"
          width={['100%', '100%', '100%', '100%', '75%']}
          mx="auto"
        >
          <Flex
            position="relative"
            mt={[4]}
            justifyContent={['center', 'center', 'flex-start']}
            width="100%"
          >
            <RichText render={data.heading} />
          </Flex>
          <FlexColumnGrid spaceBetween={[3]} columns={[1, 1, 2, 3]}>
            {serviceCards}
          </FlexColumnGrid>
        </Box>
      </LayoutBox>
    </Background>
  )
}

export default ServiceGrid
