//@flow
import styled from 'styled-components'
import { styling, positioning } from '../../helpers'
import { compose, system } from 'styled-system'

export const Ul = styled.ul`
  box-sizing: border-box;
  list-style: disc inside none;
  padding-left: 0;
  ${compose(
    positioning,
    styling
  )};
  ${system({
    listStyle: true,
  })};
`
export const Ol = styled.ul`
  box-sizing: border-box;
  list-style: decimal inside none;
  padding-left: 0;
  ${compose(
    positioning,
    styling
  )};
  ${system({
    listStyle: true,
  })};
`
