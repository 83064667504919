//@flow
import { useContext, useMemo } from 'react'
import { themeGet } from '@styled-system/theme-get'
import getVal from '../helpers/getResponsiveValue'
import { PageThemeContext } from '../context'
type Scale = string
type SystemStyle = string | number | (string | number)[]

function useScale(
  scale: Scale,
  fallback?: SystemStyle = [0, 4, 8, 16, 24, 48, 96],
  innerTheme?: { [string]: string | number | (string | number)[] }
): mixed {
  const theme = innerTheme || useContext(PageThemeContext)
  const tG = useMemo(() => (str, fbk) => themeGet(str, fbk)({ theme }), [
    scale,
    fallback,
    theme,
  ])

  return function(...vals: SystemStyle[]) {
    const f = (fallback && getVal(fallback)) || null

    const get = (val, f) => {
      const n = getVal(val)
      if (typeof n === 'number' && n < 0) {
        return `-${tG(`${scale}.${Math.abs(n)}`, f)}`
      }
      return tG(`${scale}.${n}`, f)
    }

    const themeVals = vals.map(v => get(v, f))

    return themeVals
  }
}

export default useScale
