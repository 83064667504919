//@flow

import * as React from 'react'
import styled from 'styled-components'
import { variant } from 'styled-system'
import { Link } from 'gatsby'
import { Text } from './text-new'

type TextLinkType = {
  link_type?: string | void,
  url?: string,
  target?: string,
  children?: React.Node,
  [string]: mixed,
}
//typography
//what color (inverted, primary, secondary, default)
//how the underline works (permanent, on hover, on focus, on hover and focus, never)

const defaultStyles = variant({
  variants: {
    default: {
      color: 'secondaryShades.40',
    },
    inverted: {
      color: 'invertedText.40',
    },
  },
})

const visitedStyles = variant({
  variants: {
    default: {
      color: 'secondaryShades.30',
    },
    inverted: {
      color: 'invertedText.90',
    },
  },
})

const hoverStyles = variant({
  variants: {
    default: {
      color: 'secondaryShades.50',
      textDecoration: 'underline',
    },
    inverted: {
      color: 'invertedText.0',
      textDecoration: 'underline',
    },
  },
})

const selectedStyles = variant({
  variants: {
    default: {
      background: 'primaryShades.90',
      color: 'secondaryShades.90',
    },
    inverted: {
      background: 'secondaryShades.30',
      color: 'primaryShades.60',
    },
  },
})

const LinkStyle = styled(Text)`
  text-decoration: none;
  ${defaultStyles}
  &:visited {
    ${visitedStyles}
  }
  &:hover {
    ${hoverStyles}
  }
  &::selected {
    ${selectedStyles}
  }
`

function TextLink({
  link_type,
  url,
  target,
  children,
  ...props
}: TextLinkType) {
  const gatsbyLink = link_type === 'Document'
  return (
    <LinkStyle
      as={gatsbyLink ? Link : 'a'}
      to={gatsbyLink ? target : null}
      href={!gatsbyLink ? url : null}
      {...props}
    >
      {children}
    </LinkStyle>
  )
}

export default TextLink
