//@flow

import * as React from 'react'

import { BadgeSlider } from './badgeSlider'
import { Flex } from '../../atoms/cards'
import { Background } from './styles'

type PropTypes = {
  [string]: mixed,
}

function CredsBadge(props: PropTypes) {
  return (
    <Background
      position="relative"
      bg="base.0"
      width={['8em', '9em', '10em', '11em', '12em']}
      height={['8em', '9em', '10em', '11em', '12em']}
      boxShadow="p2"
      {...props}
    >
      <Flex
        height="100%"
        width="100%"
        p={[1, 1, 2, 2, 3]}
        justifyContent="center"
        alignItems="center"
        position="relative"
      >
        <BadgeSlider />
      </Flex>
    </Background>
  )
}

export default CredsBadge
