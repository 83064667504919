//@flow
import * as React from 'react'
import styled from 'styled-components'
import { Icon as NewIcon } from '../../atoms/icon-new/icon'
import { Text } from '../../atoms/text/text-new'
import { GoogleReviews } from '../../../images/SVG/Badge/'
import { Flex, Box } from '../../atoms/cards'

type PropTypes = {
  data: { name: string, testimonial: string, locality: string },
  [string]: mixed,
}

const Decoration = styled(NewIcon)`
  transform: translate(-50%, -50%);
`

const Gbadge = styled(GoogleReviews)`
  height: 1.5em;
`

function Testimonial({ data, ...props }: PropTypes) {
  const { name, testimonial, locality } = data
  return (
    <Flex
      {...props}
      bg="white"
      borderRadius={3}
      position="relative"
      pt={6}
      pb={5}
      flexDirection="column"
      alignItems="center"
      boxShadow="p2"
    >
      <Decoration
        icon="QUOTE"
        size={4}
        left="50%"
        top="0"
        position="absolute"
        display="block"
        svgProps={{
          fill: 'primaryShades.60',
        }}
      />
      <Flex justifyContent="center" alignItems="center">
        <Text
          as="p"
          typography="paragraph"
          textAlign="center"
          color="defaultText.50"
          width={['87.5%', '87.5%', '75%']}
          fontStyle="italic"
        >
          {testimonial}
        </Text>
      </Flex>
      <Text
        as="p"
        typography="paragraph"
        textTransform="uppercase"
        letterSpacing={3}
        fontSize={[0, 1, 1, 2]}
        lineHeight={3}
        textAlign="center"
        color="defaultText.90"
        fontWeight="200"
        mt={3}
      >
        &mdash;{name}, {locality}
      </Text>
      <Box mt={3}>
        <Gbadge />
      </Box>
    </Flex>
  )
}

export default Testimonial
