//@flow

import {
  type SharpImg,
  type PrismicImg,
  type RichTextAry,
} from '../../../../types/flow/gatsby-source-prismic-graphql'
import { type PromotionLrgProps } from '../promotionLrg'

export type PromotionLrgData = {|
  type: 'promotion',
  primary: {
    background_image: PrismicImg,
    background_imageSharp: SharpImg,
    heading: RichTextAry,
    description: RichTextAry,
  },
|}

export function modelPromotionLrgData({
  primary,
}: PromotionLrgData): PromotionLrgProps {
  const {
    background_image,
    background_imageSharp,
    heading,
    description,
  } = primary
  return {
    title: heading,
    description: description,
    image: { ...background_image, ...background_imageSharp },
  }
}
