//@flow
import { css } from 'styled-components'
import { css as systemCss } from '@styled-system/css'
import { variant } from 'styled-system'
import { textStyles } from '../../text/textStyles'
import styles from './baseStyles'

export const defaultStyles = variant({
  variants: {
    inverted: {
      color: 'invertedText.40',
    },
    primary: {
      color: 'defaultText.40',
    },
  },
})

export const hoverStyles = variant({
  variants: {
    inverted: {
      color: 'invertedText.0',
    },
    primary: {
      color: 'defaultText.0',
    },
  },
})

const beforeEffect = props =>
  props.hoverEffect &&
  systemCss({
    position: 'absolute',
    content: '',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: -1,
    borderRadius: 2,
    bg: 'primaryShades.60',
    opacity: 0,
    willChange: 'opacity',
    transition: 'opacity 0.1s ease-in-out',
    mixBlendMode: 'lighten',
  })

const focusRing = ({
  focusRing,
  ringRadius,
  focusOffsetX,
  focusOffsetY,
}: {
  focusRing?: mixed,
  ringRadius: mixed,
  focusOffsetX: mixed,
  focusOffsetY: mixed,
}) => {
  const offset = typeof focusRing !== 'boolean' ? focusRing : 0
  return (
    focusRing &&
    systemCss({
      borderColor: 'secondaryShades.40',
      content: '',
      top: focusOffsetY || offset,
      right: focusOffsetX || offset,
      bottom: focusOffsetY || offset,
      left: focusOffsetX || offset,
      zIndex: 1,
      borderRadius: ringRadius || 2,
    })
  )
}

export default css`
display: flex;
justify-content: center;
align-items: center;
  ${styles}
  ${textStyles}
  ${defaultStyles}
  &::before {
    ${beforeEffect}
  }
  &:visited {
    ${defaultStyles}
  }
  &:hover {
    ${hoverStyles}
    &::before {
      opacity: 0.7;
    }
  }
  &:focus {
    outline: none;
    &::after {
      position: absolute;
      content: '';
      border: solid 2px;
      ${
        //$FlowFixMe
        focusRing
      }
    }
  }
`
