//@flow

import { variant } from 'styled-system'

export const ui = {
  fontFamily: 0,
  fontSize: [0, 1, 1, 1, 1, 2],
  fontWeight: 0,
  letterSpacing: [1],
  color: 'defaultText.40',
  lineHeight: 3,
}

export const paragraph = {
  ...ui,
  lineHeight: [2, 3],
  my: [1, 1, 1, 1, 1, 2],
  color: 'defaultText.50',
}

export const pre = Object.assign(
  {},
  { ...paragraph },
  {
    fontFamily: 'Consolas, Monaco, monospace',
    p: 3,
    borderRadius: 3,
    whiteSpace: 'pre-wrap',
    bg: 'primaryShades.20',
    color: 'primaryShades.60',
  }
)

export const code = Object.assign(
  {},
  { ...paragraph },
  {
    fontFamily: 'Consolas, Monaco, monospace',
    px: 1,
    py: 0.5,
    bg: 'primaryShades.30',
    color: 'primaryShades.60',
  }
)

export const strong = {
  fontWeight: 1,
  color: 'defaultText.30',
}

export const em = {
  fontStyle: 'italic',
  color: 'primary.50',
}

export const link = {
  ...ui,
  lineHeight: [2, 3],
  my: 1,
  px: '0.25em',
  cursor: 'pointer',
  color: 'secondaryShades.50',
  textDecoration: 'underline',
}

export const listItem = {
  ...ui,
  lineHeight: [2, 3],
  pb: 1,
  color: 'defaultText.60',
}
export const oListItem = {
  ...ui,
  lineHeight: [2, 3],
  color: 'defaultText.60',
}

export const heading1 = {
  fontFamily: 1,
  fontSize: [6, 6, 6, 7, 8],
  letterSpacing: 4,
  lineHeight: 1,
  color: 'primaryShades.80',
  mb: 3,
  mt: 5,
}
export const heading2 = {
  fontFamily: 1,
  fontSize: [5, 5, 5, 6, 6],
  letterSpacing: 3,
  lineHeight: 2,
  color: 'primaryShades.60',
  mb: 3,
  mt: 5,
}
export const heading3 = {
  fontFamily: 1,
  fontSize: [4, 4, 4, 5, 5],
  letterSpacing: 3,
  lineHeight: 1,
  color: 'primaryShades.60',
  mb: 3,
  mt: 5,
}
export const heading4 = {
  fontFamily: 1,
  fontSize: [3, 3, 3, 4, 4],
  letterSpacing: 5,
  lineHeight: 2,
  color: 'primaryShades.60',
  mb: 2,
  mt: 5,
}
export const heading5 = {
  fontFamily: 1,
  fontSize: [2, 2, 2, 3, 3],
  letterSpacing: 5,
  lineHeight: 3,
  color: 'primaryShades.60',
  mb: 2,
  mt: 3,
}

export const primarySelected = {
  bg: 'primaryShades.30',
  color: 'primaryShades.80',
}
export const secondarySelected = {
  bg: 'secondaryShades.40',
  color: 'defaultText.0',
}

export const primaryInverted = {
  bg: 'primaryShades.50',
  color: 'invertedText.0',
}
export const secondaryInverted = {
  bg: 'secondaryShades.50',
  color: 'invertedText.0',
}

export const textStyles = variant({
  prop: 'typography',
  variants: {
    ui,
    link,
    paragraph,
    listItem,
    oListItem,
    heading1,
    heading2,
    heading3,
    heading4,
    heading5,
    strong,
    em,
    pre,
    code,
  },
})

export const selectedStyles = variant({
  prop: 'typography',
  variants: {
    ui: primarySelected,
    link: secondarySelected,
    paragraph: primarySelected,
    listItem: primarySelected,
    oListItem: primarySelected,
    strong: secondarySelected,
    em: secondarySelected,
    heading1: secondaryInverted,
    heading2: secondaryInverted,
    heading3: secondaryInverted,
    heading4: secondaryInverted,
    heading5: secondaryInverted,
    pre: primaryInverted,
    code: primaryInverted,
  },
})
