//@flow

import styled from 'styled-components'
import { positioning } from '../../../helpers'
import { Box } from '../../../atoms/cards'

const Nav = styled(Box)`
  ${positioning};
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

export default Nav
