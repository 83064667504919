//@flow

import * as React from 'react'

import { useBreakpoint } from '../../../shared/hooks'
import { BreakpointContext } from '../../../shared/context'

function BreakpointContextProvider(props: { children?: React.Node }) {
  const breakpoint = useBreakpoint()
  return <BreakpointContext.Provider value={breakpoint} {...props} />
}

export default BreakpointContextProvider
