const { breakpoints } = require('./breakpoints')
const { typography } = require('./typography') //multiple properties
const { colors } = require('./color')
const { shadows } = require('./shadow')
const { sizes } = require('./size')
const { borders } = require('./borders') //multiple properties
const { space } = require('./space')
const { zIndices } = require('./zIndices')

const theme = {
  breakpoints,
  space,
  colors,
  shadows,
  zIndices,
  sizes,
  ...typography,
  ...borders,
}
exports.theme = theme
exports.makeTheme = function(modifier = 'main') {
  const mode = Object.prototype.hasOwnProperty.call(
    theme.colors.modes,
    modifier
  )
    ? modifier
    : 'main'
  return Object.assign(
    {},
    { ...theme },
    { mode: mode },
    {
      colors: Object.assign({}, { ...theme.colors }, { ...colors.modes[mode] }),
    },
    {
      shadows: Object.assign(
        {},
        { ...theme.shadows },
        { ...shadows.modes[mode] }
      ),
    }
  )
}
