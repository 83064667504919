//@flow

import * as React from 'react'

import Container from './styles/container'
import Image from './styles/image'
import Frame from './styles/frame'
import Text from '../../atoms/text'
import { Box } from '../../atoms/cards'
import { Button } from '../../atoms/buttons/button-new/button'
import { themeGet } from '@styled-system/theme-get'

import {
  type SharpImg,
  type PrismicImg,
  type ColorTheme,
} from '../../../types/flow/gatsby-source-prismic-graphql'
import { theme as siteTheme } from '../../../theme'

export type ServiceGridItem = {
  sharpImage: { ...SharpImg, ...PrismicImg },
  serviceName: string,
  link: string,
  id: string,
  theme: ColorTheme,
}

const BACKGROUND_COLOR = 'primaryShades.60'

const getThemeColor = theme => value =>
  themeGet(
    `colors.modes.${theme}.${value}`,
    value
  )({
    theme: siteTheme,
  })

function ServiceCard({
  sharpImage,
  serviceName,
  link,
  theme,
  ...props
}: ServiceGridItem) {
  const fromTheme = theme && getThemeColor(theme)
  const backgroundColor = fromTheme(BACKGROUND_COLOR)
  const {
    childImageSharp: { fluid },
    ...img
  } = sharpImage
  return (
    <Button
      {...props}
      buttonStyle="ghost"
      to={`/${link}`}
      focusRing={-2}
      ringRadius={3}
      px={0}
      pb={1}
      render={({ hover, focus }) => (
        <Container>
          <Frame
            borderRadius={2}
            width="100%"
            position="relative"
            bg={backgroundColor}
          >
            <Image
              width="100%"
              position="relative"
              height={[8, 8, 8, 8, 9]}
              objectFit="cover"
              objectPosition="50% 50%"
              fluid={fluid}
              {...img}
              hover={hover}
              focus={focus}
            />
          </Frame>
          <Box mt={[2]}>
            <Text
              display="block"
              position="relative"
              textAlign={['center', 'center', 'left']}
              fontWeight={[1]}
              color="grey80"
              zIndex={3}
            >
              {serviceName}
            </Text>
          </Box>
        </Container>
      )}
    ></Button>
  )
}

export default ServiceCard
