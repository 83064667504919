//@flow

import { useEffect, useRef, useState } from 'react'

export function useInterval(
  callback: (*) => mixed,
  delay: number,
  pauseWhenPageHidden?: boolean = true
) {
  const [hidden, setHidden] = useState(false)
  const savedCallback: { current?: (*) => mixed } = useRef()

  useEffect(() => {
    const setVisbility = () => setHidden(document.hidden)
    setVisbility()
    document.addEventListener('visibilitychange', setVisbility)
  })

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current && savedCallback.current()
    }
    if ((delay !== null && !hidden) || !pauseWhenPageHidden) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay, hidden])
}
