//@flow

import {
  compose,
  layout,
  space,
  position,
  flex,
  order,
  alignSelf,
  justifySelf,
  color,
  border,
  boxShadow,
  borderRadius,
  background,
  typography as sysTypography,
  system,
} from 'styled-system'

export const positioning = compose(
  layout,
  space,
  position,
  background,
  alignSelf,
  justifySelf,
  flex,
  order
)

export const styling = compose(
  color,
  border,
  boxShadow,
  borderRadius,
  background
)

export const typography = compose(
  sysTypography,
  system({
    textTransform: true,
  })
)
