//@flow

import styled from 'styled-components'

import { Box } from '../atoms/cards'

const FullWidthBox = styled(Box).attrs(props => ({
  zIndex: props.zIndex || 1,
  position: props.position || 'relative',
}))`
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
`

export default FullWidthBox
