module.exports = {
  homepage: require('./homepage.json'),
  locations: require('./locations.json'),
  services: require('./service.json'),
  testimonials: require('./testimonials.json'),
  theme: require('./theme.json'),
  four_oh_four: require('./fourOhFour.json'),
  contact: require('./contact.json'),
  legal: require('./legalPage.json'),
  gdpr_notice: require('./gdprNotice'),
}
