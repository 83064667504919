//@flow

import { type HighlightsProps } from '../highlightGrid'
import { type RichTextAry } from '../../../../types/flow/gatsby-source-prismic-graphql'

export type HighlightsData = {|
  type: 'highlights_row',
  fields: {
    heading: RichTextAry,
    description: RichTextAry,
  }[],
|}

export function modelHighlightsData({
  fields,
}: HighlightsData): HighlightsProps {
  return {
    items: fields.map(item => {
      const { heading, description } = item
      return {
        heading,
        description,
      }
    }),
  }
}
