//@flow
import * as React from 'react'
import styled from 'styled-components'
import { typography, styling, positioning } from '../../helpers'
import { textStyles, selectedStyles } from './textStyles'

type TextStyles =
  | 'link'
  | 'paragraph'
  | 'heading1'
  | 'heading2'
  | 'heading3'
  | 'heading4'
  | 'heading5'

const getSematicElement = (prop?: textStyles | string) => {
  switch (prop) {
    case 'link':
      return 'a'
    case 'paragraph':
      return 'p'
    case 'em':
      return 'em'
    case 'strong':
      return 'string'
    case 'heading1':
      return 'h1'
    case 'heading2':
      return 'h2'
    case 'heading3':
      return 'h3'
    case 'heading4':
      return 'h4'
    case 'heading5':
      return 'h5'
    case 'listItem':
      return 'li'
    case 'oListItem':
      return 'li'
    case 'pre':
      return 'pre'
    default:
      return 'span'
  }
}

export const StyledText = styled.span`
  ${textStyles}
  ${typography}
  ${styling}
  ${positioning}
  &::selection {
    ${selectedStyles}
  }
`

type TextProps = {
  typography?: TextStyles | 'ui' | string,
  as?: string | React.Node,
}

export function Text({ as, typography = 'ui', ...props }: TextProps) {
  const elementAs = as || getSematicElement(typography) || undefined
  const newProps = Object.assign({}, { ...props }, { elementAs, typography })
  return <StyledText as={elementAs} {...newProps} />
}
