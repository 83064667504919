import React from 'react'
import { Link as PrismicLink, Elements } from 'prismic-reactjs'
import { Link } from 'gatsby'
import { linkResolver } from '../../../utils/linkResolver'
import { Text } from '../../atoms/text/text-new'
import { Ul, Ol } from '../../atoms/lists/lists'

const propsWithUniqueKey = function(props, key) {
  return Object.assign(props || {}, { key })
}

function getVariant(type) {
  switch (type) {
    case Elements.paragraph:
      return 'paragraph'
    case Elements.listItem:
      return 'listItem'
    case Elements.oListItem:
      return 'oListItem'
    case Elements.em:
      return 'em'
    case Elements.strong:
      return 'strong'
    case Elements.heading1:
      return 'heading1'
    case Elements.heading2:
      return 'heading2'
    case Elements.heading3:
      return 'heading3'
    case Elements.heading4:
      return 'heading4'
    case Elements.heading5:
      return 'heading5'
    case Elements.preformatted:
      return 'pre'
    default:
      return undefined
  }
}

export default function(type, element, content, children, key) {
  var props = {}

  const typography = getVariant(type)

  if (typography) {
    const textProps = propsWithUniqueKey({ typography }, key)
    return React.createElement(Text, textProps, children)
  }

  if (type === Elements.list) {
    return React.createElement(Ul, propsWithUniqueKey(props, key), children)
  }
  if (type === Elements.oList) {
    return React.createElement(Ol, propsWithUniqueKey(props, key), children)
  }
  if (type === Elements.label) {
    props = element.data
      ? Object.assign({}, { className: element.data.label })
      : {}
    return React.createElement(Text, propsWithUniqueKey(props, key), children)
  }
  if (type === Elements.span) {
    if (content) {
      return content.split('\n').reduce((acc, p) => {
        if (acc.length === 0) {
          return [p]
        } else {
          const brIndex = (acc.length + 1) / 2 - 1
          const br = React.createElement('br', propsWithUniqueKey({}, brIndex))
          return acc.concat([br, p])
        }
      }, [])
    } else {
      return null
    }
  }
  // Generate links to Prismic Documents as <Link> components
  if (type === Elements.hyperlink) {
    let result = ''
    const url = PrismicLink.url(element.data, linkResolver)

    if (element.data.link_type === 'Document') {
      result = (
        <Text typography="link" as={Link} to={url} key={key}>
          {content}
        </Text>
      )
    } else {
      const target = element.data.target
        ? { target: element.data.target, rel: 'noopener' }
        : {}
      result = (
        <Text href={url} as="a" typography="link" {...target} key={key}>
          {content}
        </Text>
      )
    }
    // If the image is also a link to a Prismic Document, it will return a <Link> component
    if (type === Elements.image) {
      let result = (
        <img
          src={element.url}
          alt={element.alt || ''}
          copyright={element.copyright || ''}
        />
      )

      if (element.linkTo) {
        const url = PrismicLink.url(element.linkTo, linkResolver)

        if (element.linkTo.link_type === 'Document') {
          result = (
            <Link to={url} key={key}>
              {result}
            </Link>
          )
        } else {
          const target = element.linkTo.target
            ? { target: element.linkTo.target, rel: 'noopener' }
            : {}
          result = (
            <a href={url} {...target}>
              {result}
            </a>
          )
        }
      }
      const wrapperClassList = [element.label || '', 'block-img']
      result = (
        <p className={wrapperClassList.join(' ')} key={key}>
          {result}
        </p>
      )
      return result
    }
    return result
  }

  return null
}
