//@flow
import { css } from 'styled-components'
import { compose } from 'styled-system'
import { styling, positioning, typography } from '../../../helpers'
export { styling, positioning, typography }

export const baseStyles = css`
  display: inline-flex;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  border: none;
  text-decoration: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: none;
  box-sizing: border-box;
  border: 2px solid transparent;
  &::-moz-focus-inner {
    border: 0 !important;
  }
`

export default css`
  ${baseStyles}
  ${compose(
    baseStyles,
    styling,
    typography,
    positioning
  )}
`
