//@flow

import * as React from 'react'
import { Flex, Box } from '../../atoms/cards'
import Background from '../../containers/background'
import LayoutBox from '../../containers/layoutBox'
import FlexColumnGrid from '../../containers/flexColumnGrid'
import Image from '../../atoms/image'
import { RichText } from '../../molecules/richTextArticle'

import {
  type SharpImg,
  type PrismicImg,
  type RichTextAry,
} from '../../../types/flow/gatsby-source-prismic-graphql'

export type PromotionLrgProps = {
  title: RichTextAry,
  description: RichTextAry,
  image: { ...PrismicImg, ...SharpImg },
  buttonText?: string,
  link?: string,
}

function PromotionLrg({ data, ...props }: { data: PromotionLrgProps }) {
  const { title, description, image } = data
  return (
    <Background
      pt={[4, 4, 5, 6]}
      pb={[5, 5, 4, 0]}
      justifyContent="center"
      {...props}
    >
      <LayoutBox>
        <Box
          as="section"
          width="100%"
          mx="auto"
          position={['static', 'static', 'static', 'relative']}
        >
          <Flex
            alignItems="center"
            position={['absolute', 'absolute', 'absolute', 'relative']}
            left={0}
            right={0}
          >
            <Image
              height={[11, 11, 11, 12, 12, 12]}
              width={['100%', '100%', '100%', '75%', '62.5%', '50%']}
              ml={[0, 0, 0, 0, 0, '12.5%']}
              borderRadius={[0, 0, 0, 2]}
              objectFit="cover"
              fluid={image.childImageSharp.fluid}
            />
          </Flex>
          <FlexColumnGrid
            position={['relative', 'relative', 'relative', 'absolute']}
            top={0}
            left={0}
            right={0}
            height="100%"
            alignItems="center"
            columns={[8]}
            spaceBetween={[3]}
          >
            <Box
              bg="white"
              mt={[12, 12, 11, 0]}
              colSpan={[8, 8, 6, 5, 4, 3]}
              nudge={[0, 0, 1, 3, 4, 4]}
              pt={[2, 2, 3]}
              pb={[3, 3, 4]}
              px={[5, 5, 6]}
              width="100%"
              borderRadius={[2]}
              boxShadow="p2"
              mr={0}
            >
              <RichText render={title} />
              <Box mt={4}>
                <RichText render={description} />
              </Box>
            </Box>
          </FlexColumnGrid>
        </Box>
      </LayoutBox>
    </Background>
  )
}

export default PromotionLrg
