const fontSizes = [
  '0.875rem',
  '1rem',
  '1.125rem',
  '1.25rem',
  '1.5rem',
  '2rem',
  '2.5rem',
  '3.5rem',
  '4.5rem',
]

const lineHeights = [1, 1.125, 1.25, 1.5, 2]

const fontWeights = [400, 500, 700]

const letterSpacings = [
  '-0.02em',
  '-0.01em',
  '-0.005em',
  '0em',
  '0.001em',
  '0.015em',
]

letterSpacings.normal = letterSpacings[3]

const fonts = [
  `'Colfax', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif`,
  `'Marche', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif`,
]

fonts.ui = fonts[0]
fonts.display = fonts[1]

exports.typography = {
  fonts,
  lineHeights,
  fontSizes,
  fontWeights,
  letterSpacings,
}
