//@flow

import styled from 'styled-components'

import { Box } from '../../../atoms/cards'

export const Background = styled(Box)`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transform: scaleX(1.00625);
  transform-origin: center center;
`

export const Ul = styled.ul`
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
`
export const Li = styled.li`
  position: absolute;
  text-decoration: none;
  padding: 0;
  margin: 0;
`
