//@flow

exports.sizes = [
  '0px',
  '1rem',
  '1.5rem',
  '2rem',
  '3rem',
  '4rem', //5
  '6rem',
  '8rem',
  '10rem',
  '12rem',
  '16rem', //10
  '24rem',
  '32rem',
  '48rem',
  '72rem',
  '96rem', //15
]
