//@flow
import { useContext } from 'react'
import { themeGet } from '@styled-system/theme-get'
import { PageThemeContext } from '../context'
type Obj = {
  [string]: string,
}

function mapColorConfig(config: Obj): Obj {
  const theme = useContext(PageThemeContext)
  const colors = Object.entries(config).reduce((acc, [key, val]) => {
    return {
      ...acc,
      //$FlowFixMe
      [key]: themeGet(`colors.${val}`, 'black')({ theme }),
    }
  }, {})
  return colors
}

export default mapColorConfig
