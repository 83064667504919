//@flow

import * as React from 'react'

import makeStyles from '../helpers/makeResponsiveStyleArray'

import { Flex, Box } from '../atoms/cards'

type Style = string | number
type StyleArray = Style[]
type SystemStyle = Style | StyleArray
type Nums = number | number[]

type PropTypes = {
  spaceBetween?: SystemStyle,
  children?: React.Node,
  columns?: Nums,
  widows?: 'center' | 'flex-start' | 'flex-end',
  elementsAs?: React.Node,
  childrenProps?: { [string]: mixed },
  flexDirection?: SystemStyle,
  justifyContent?: SystemStyle,
  alignItems?: SystemStyle,
  nowrap?: boolean,
}

const returnLongestLength = (...arys) => {
  return Math.max(...arys.map(a => a.length))
}

const pick = <T, M>(val: T[], i: number, fallback?: M): T | M =>
  typeof val[i] !== 'undefined' ? val[i] : fallback || val[val.length - 1]

const makeArray = <T>(val: T | T[]): T[] =>
  Array.isArray(val) ? [...val] : [val]

const zipAll = <T, V>(fn: (T[]) => V, ...items: T[]): V[] => {
  const arys = items.map(i => makeArray(i))
  let i = 0
  const l = returnLongestLength(...arys)
  let zipped = []
  while (i < l) {
    //$FlowFixMe
    const vals = arys.map(a => pick(a, i))
    zipped.push(fn(...vals))
    i++
  }
  return zipped
}

const detect = (what, str) => typeof str === 'string' && str.search(what) > 0
const reverseColumn = str => [detect('reverse', str), detect('olumn', str)]
const nudgeStart = str => (!str ? true : detect('start', str))
const nudgeEnd = str => detect('end', str)
const startEnd = str => [nudgeStart(str), nudgeEnd(str)]

function FlexColumnGrid({
  children,
  columns = 1,
  elementsAs = null,
  spaceBetween,
  widows,
  nowrap,
  ...props
}: PropTypes) {
  const negMargin = React.useMemo(
    () => spaceBetween && makeStyles(spaceBetween, n => n - 2 * n),
    [spaceBetween]
  )

  const fractions = React.useMemo(() => {
    const colWidths: number[] = makeStyles(columns, c => (1 / c) * 100)

    function fn([reverse, column], justify, align, colWidth) {
      const arrange = (x, y, z) => (x ? z : y)
      const arrangement = arrange(column, justify, align)
      const [start, end] = startEnd(arrangement)

      const fwd = (!reverse && start) || (end && reverse)
      const bwd = (reverse && start) || (end && !reverse)
      return [colWidth, fwd ? colWidth : 0, bwd ? colWidth : 0]
    }

    const revcol = makeArray(props.flexDirection).map(d => reverseColumn(d))

    const { justifyContent, alignItems } = props

    return zipAll(fn, revcol, justifyContent, alignItems, colWidths).reduce(
      (acc, a) => ({
        width: [...(acc.width || []), a[0]],
        ml: [...acc.ml, a[1]],
        mr: [...acc.mr, a[2]],
      }),
      {
        ml: [],
        mr: [],
      }
    )
  }, [columns, props.justifyContent, props.alignItems])

  //$FlowFixMe
  const getPercent = (val, multiplier) => `${val * multiplier}%`

  const getPercents = (vals, multipliers) =>
    zipAll(getPercent, vals, multipliers)

  return (
    <Flex
      flexWrap="wrap"
      justifyContent={widows || 'flex-start'}
      m={negMargin}
      {...props}
    >
      {React.Children.map(children, (child, i) => {
        const index = i + 1

        const {
          colSpan = 1,
          nudge = 0,
          order,
          wrapperProps,
          ...childProps
        }: {
          colSpan?: Nums,
          nudge: Nums,
          order: mixed,
          wrapperProps: { ...mixed },
          childProps: { ...mixed },
        } = child.props || {}
        //$FlowFixMe
        const width = getPercents(fractions.width, colSpan)

        const margins = {
          ml: getPercents(fractions.ml, nudge),
          mr: getPercents(fractions.mr, nudge),
        }
        const newProps = Object.assign(
          {},
          { ...(nowrap ? childProps : {}) },
          {
            order,
            width,
            ...margins,
            p: spaceBetween,
            as: elementsAs,
          },
          { ...wrapperProps }
        )

        if (nowrap) {
          return React.cloneElement(child, { ...newProps })
        }

        return (
          <Box key={index} {...newProps}>
            {child}
          </Box>
        )
      })}
    </Flex>
  )
}

export default FlexColumnGrid
