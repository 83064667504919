//@flow

import * as React from 'react'
import { Flex, Box } from '../../atoms/cards'
import { RichText } from '../../molecules/richTextArticle'

import { type RichTextAry } from '../../../types/prismicSchema'

type PropTypes = {
  title: RichTextAry,
  description: RichTextAry,
}

function HighlightCard({ title, description, ...props }: PropTypes) {
  return (
    <Box
      border="1px solid"
      borderColor="base.20"
      width="100%"
      bg="white"
      {...props}
      borderRadius={[2]}
      padding={2}
    >
      <Flex>
        <Box pb={[2]} ml={[2]}>
          <RichText render={title} />
          <RichText render={description} />
        </Box>
      </Flex>
    </Box>
  )
}

export default HighlightCard
